import "../css/style.scss";
import "../css/datapribadi/datapribadi.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Full from './FullLoad';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useParams, Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
const plus = <FontAwesomeIcon icon={faPlus} />;
const minus = <FontAwesomeIcon icon={faMinus} />;

export default function Datapribadi() {
  const token = localStorage.getItem("token");

  const params = useParams();

  const history = useHistory();
  const [isLogin, SetIsLogin] = useState(false);
  const [loader, showLoader, hideLoader] = Full();

  let decoded = base64_decode(params.encoded);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (!token) {
      SetIsLogin(true);
      history.push("/error");
    } else {
      SetIsLogin(true);
    }
  }, []);
  


  const [inputFields, setInputFields] = useState([{
    fk_pelamar: decoded
  }]);

  const handleFormChange = (index, event) => {
   let data = [...inputFields];
   data[index][event.target.id] = event.target.value;
   setInputFields(data);
  }



  const tambah = () => {
   let newfield = ({
    fk_pelamar: decoded
   })
   setInputFields([...inputFields, newfield])
  }

  const hapus = (index) => {
   let data = [...inputFields];
   data.splice(index, 1)
   setInputFields(data)
  }
  
   const submit = async (e) => {
    showLoader();
    e.preventDefault();
    axios({
      method: 'post',
      url: 'https://hris.anyargroup.co.id/api/kerja/insert',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: inputFields
     
  })
 
      .then((response) => {
        hideLoader()
        Swal.fire({
          title: 'Input Data Success',
          showConfirmButton: false,
          icon: 'success'
      })
      history.push("/profile");
      })
      .catch((err) => {
        hideLoader()
        Swal.fire({
          title: 'Please check your input',
          showConfirmButton: false,
          icon: 'warning'
      })
      });
   }

  return (
    <>
     {isLogin ? (
       <div className="datapribadi">
       <div className="container datapribadi-label">
         <div className="label">
           <h4 className="datapribadi-title">
             <span className="datapribadi-garis">Personal Data </span>
           </h4>
           <label className="datapribadi-title-content-1">
             Lengkapi form yang ada di bawah ini, sesuai data pribadi anda
           </label>
           <br></br>
           <label className="datapribadi-title-content-2">
             Complete the form below, according to your personal data
           </label>
           
           <div className="datapribadi-garis-1"></div>
         </div>
         </div>

       <div className="container datapribadi-content">
         <div className="content">
           <div className="row form-row">
             <form encType="multipart/form-data" onSubmit={submit} className="form-datapribadi">

                  {/* Form pekrjaan */}
                <div className="col-12 form-col">
                 <h5 class="card-title">Job history</h5>
                 <div class="card">
                   <div class="card-body">
                   <div className="btn btn-sm btn-add" onClick={tambah}>{plus}</div>
                   <div className="content-table">
               
                       <table  className="table table-borderless table-content">
                               <thead className="head-table-head">
                                 <tr className="head-table">
                                   <th className="riwayat-company">Company</th>
                                   <th className="riwayat-position">Position</th>
                                   <th className="riwayat-address">Address</th>
                                   <th className="riwayat-tanggalmasuk">Date of entry</th>
                                   <th className="riwayat-tanggalkeluar">Date of out</th>
                                   <th className="riwayat-alasan">Reason to leave</th>
                                   
                                 </tr>
                               </thead>
                             {inputFields.map((input, index) => {
                                 return (
                                 <tbody key={index} className="body-table-body">
                                   <tr className="body-table">
   
                                 <td className="content-riw-company">
                                   <input className="form form-control form-control-sm " placeholder="Input company" name="perusahaan" id="perusahaan" type="text" value={input.perusahaan}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-position">
                                   <input className="form form-control form-control-sm " placeholder="Input position" name="posisi" id="posisi" type="text" value={input.posisi}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-address">
                                   <input className="form form-control form-control-sm " placeholder="Input address" name="alamat" id="alamat" type="text" value={input.alamat}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \.\-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-tanggalmasuk">
                                   <input className="form form-control form-control-sm"  name="tanggal_masuk" id="tanggal_masuk" type="date" value={input.tanggal_masuk}
                                   onChange={(event) =>handleFormChange(index, event)}/>
                                 </td>

                                 <td className="content-riw-tanggalkeluar">
                                   <input className="form form-control form-control-sm"  name="tanggal_keluar" id="tanggal_keluar" type="date" value={input.tanggal_keluar}
                                     onChange={(event) =>handleFormChange(index, event)}/>
                                 </td>

                                 <td className="content-riw-alasan">
                                   <textarea className="form form-control form-control-sm " rows="1"  placeholder="Input reason of leave" name="keterangan" id="keterangan" type="textarea" value={input.keterangan}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \,\.\-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 
                                 <td>
                                 <button className="btn btn-sm btn-hapus" disabled={index === 0} onClick={() => hapus(index)}><span className="icon">{minus}</span></button>
                                 </td>
                                 
                                
                                   </tr>
                                 </tbody>
                                );
                               })}
                       </table>
                       <span className="note">Note</span><br></br>
                   <span className="note">1. Isi <b>(-)</b> untuk semua kolom jika belum ada pengalaman kerja.</span>
                   </div>
                   

                  
                   </div>
                 </div>
               </div>
               {/* END Form */}

   

               <div className="col-12 form-button">
                 <center>
                   <button type="submit" className="button-inputdata">
                     <a className="inputdata">Submit</a>
                   </button>
                 </center>
               </div>
             </form>
             
            
           </div>
         </div>

       </div>
       {loader}
     </div>
     ):(
      <h5>data readyu</h5>
     )}
     
    </>
  );
}
