import "../css/style.scss";
import "../css/profile/profile.scss";
import axios from 'axios';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import download from "../img/download.png";
import Moment from 'moment';
import edit from "../img/edit.png";
import gembok from "../img/gembok.png";
import Full from './FullLoad';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPerson, faGraduationCap, faSuitcase, faNotesMedical ,faPeopleGroup} from "@fortawesome/free-solid-svg-icons";
const file = <FontAwesomeIcon icon={faFile} />;
const person = <FontAwesomeIcon icon={faPerson} />;
const education = <FontAwesomeIcon icon={faGraduationCap} />;
const job = <FontAwesomeIcon icon={faSuitcase} />;
const healt = <FontAwesomeIcon icon={faNotesMedical} />;
const people = <FontAwesomeIcon icon={faPeopleGroup} />;


function Profile () {
    const [doc, setDoc] = useState("");
    const [datadoc, setDatadoc] = useState("");
    const [datakel, setDatakel] = useState([]);
    const [kel, setKel] = useState("");
    const [didiks, setDidik] = useState("");
    const [datadidik, setDatadidik] = useState([]);
    const [jobs, setJob] = useState("");
    const [datajob, setDatajob] = useState([]);
    const [kes, setKes] = useState("");
    const [datakes, setDatakes] = useState([]);
    const [isLogin, SetIsLogin] = useState(false);
    const [user, setUser] = useState([]);
    const [profile, setProfile] = useState ([]);
    const [offering, setOffering] = useState ([]);
    const [loader, showLoader, hideLoader] = Full();
    const token = localStorage.getItem("token");
    let encoded = base64_encode(user.id);

    
//ToDo
const fetchData = async () => {
  showLoader();
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  await axios.get("https://hris.anyargroup.co.id/api/profile")
  
  .then((response) => {
    hideLoader()
    setProfile(response.data.data); 
    setOffering(response.data.cekapp.length); 
    if (response.data.lamar[0]) {
      const formData = response.data.lamar[0].id;
      axios .get("https://hris.anyargroup.co.id/api/berkas/getberkas" + "/" + formData)
      .then((res) => {
        setDoc(res.data.data.length);
        setDatadoc(res.data.data[0]);
        console.log("asdasd",res.data.data[0])
    });

    axios .get("https://hris.anyargroup.co.id/api/keluarga/kel" + "/" + formData)
    .then((res) => {
      setKel(res.data.data.length);
      setDatakel(res.data.data);
  });

  axios .get("https://hris.anyargroup.co.id/api/pendidikan/didik" + "/" + formData)
    .then((res) => {
      setDidik(res.data.data.length);
      setDatadidik(res.data.data);
  });

  axios .get("https://hris.anyargroup.co.id/api/kerja/krj" + "/" + formData)
  .then((res) => {
    setJob(res.data.data.length);
    setDatajob(res.data.data);
   
});

axios .get("https://hris.anyargroup.co.id/api/kesehatan/sehat" + "/" + formData)
.then((res) => {
  setKes(res.data.data.length);
  setDatakes(res.data.data);
});
      setUser(response.data.lamar[0]);
    } else {}
   
  })
  
}

useEffect(() => {
  if (token) {
    SetIsLogin(true);
  } else {
    SetIsLogin(false);
  }

  fetchData();
}, []);

  return (
  <div className="profile">
    
    {isLogin ? (

       <div className="container container-profile">
        
       <div className="row justify-content-center row-profile">
        
         <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-3 col-img">
         <div className="bord"></div>
       
           <div className="picture">
           <div className="bord1"></div>
           <img src={`https://hris.anyargroup.co.id/storage/pelamar/${user.photo}`}  className="img-profile" ></img>    
      
           </div>

         </div>
          <h1 className="name-picture">{user.nama_lengkap} </h1>
       </div>
       <div className="row justify-content-center row-btn-profile">
       <div className="col-lg-12 col-profile-text">
           <h5 className="profile-text">
             {profile.email} 
           </h5 >
         </div>
       
        <div style={{padding:"20px 20px"}}>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="true">{person} Profile</button>
          </li>
          {(() => {         
              if (offering !== 0) {
                  return (
                   
                    <>
                    <li class="nav-item" role="presentation">
                         <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#doc" type="button" role="tab" aria-controls="doc" aria-selected="false">{file} Document</button>
                       </li>
                       <li class="nav-item" role="presentation">
                         <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#fam" type="button" role="tab" aria-controls="fam" aria-selected="false">{people} Family</button>
                       </li>
                       <li class="nav-item" role="presentation">
                         <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#edu" type="button" role="tab" aria-controls="edu" aria-selected="false">{education} Education</button>
                       </li>
                       <li class="nav-item" role="presentation">
                         <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#job" type="button" role="tab" aria-controls="job" aria-selected="false">{job} Job</button>
                       </li>
                       <li class="nav-item" role="presentation">
                         <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#med" type="button" role="tab" aria-controls="med" aria-selected="false">{healt} Medical</button>
                       </li>
                             </>
                  )}else if (offering === 0) {
                    return (
                 
                      <div></div>
                    );
                  } 
                })()}
          

        </ul>
        
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="home-tab">
            <div className="row justify-content-end">
            <div className="col-xl-2 col-lg-3 col-sm-12 col-md-4 col-xs-6 col-btn">
                  <Link to={"/changepass"} style={{textDecoration:"none"}}>
                    <button className="btn-pwd">
                      <h5 className="text-pwd">
                        <img src={gembok} className="img-edit"></img>Change Password
                      </h5>
                    </button>
                  </Link>
                </div>
            <div className="col-xl-2 col-lg-3 col-sm-12 col-md-4 col-xs-6 col-btn">
                <Link to={"/edit"} style={{textDecoration:"none"}}>
                    <button className="btn-edit">
                      <h5 className="text-edit">
                        <img src={edit} className="img-edit"></img>Edit Profile
                      </h5>
                    </button>
                  </Link>
                </div>
            </div>

          <div className="row justify-content-center row-data">
                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-data">
                    <div className="card card-data">
                      <h5 className="title-data">Personal Data</h5>

                        <div className="container container-content">
                            <div className="row row-content">
                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data" >NIK</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.nik}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Full Name</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.nama_lengkap}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Date of Birth</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.tempat_lahir }, {Moment(user.tgl_lahir).format('DD-MM-YYYY') }</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Handphone</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {profile.phone_number}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Religion</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.agama}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Family Relationship</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.hubungan_keluarga}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Curriculum Vitae</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                              <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/pelamar/${user.cv}`} target="_blank">: {user.nama_lengkap}.pdf</a></h5>
                              </div>
                            </div>
                        </div>
                      
                    </div>
                  </div>


                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-data">
                    <div className="card card-data">
                      <h5 className="title-data">Address</h5>

                        <div className="container container-content">
                            <div className="row row-content">
                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data" >Address</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.alamat}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Rt</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.rt}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Rw</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.rw}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Village</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.desa}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">District</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.kecamatan}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Postal Code</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.kodepos}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">City</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.kota}</h5>
                              </div>

                              <label className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Province</label>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.provinsi}</h5>
                              </div>

                            </div>
                        </div>
                      
                    </div>
                  </div>
                

                  <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-data">
                    <div className="card card-data">
                      <h5 className="title-data">Education</h5>

                        <div className="container container-content">
                            <div className="row row-content">
                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data" >Last Education</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.pendidikan_terakhir}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Name Of School</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.nama_sekolah}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Major</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.jurusan}</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">From</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {Moment(user.tahun_masuk).format('DD-MM-YYYY') }</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">Until</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {Moment(user.tahun_lulus).format('DD-MM-YYYY') }</h5>
                              </div>

                              <label className="col-xl-5 col-lg-4 col-md-4 col-sm-5 col-xs-2 col-5 col-label-data">GPA</label>
                              <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-xs-10 col-7 col-content-data">
                                <h5 className="text-data">: {user.ipk} / 4.00</h5>
                              </div>


                            </div>
                        </div>
                      
                    </div>
                  </div>
            
                

              </div>
          </div>

          <div class="tab-pane fade" id="doc" role="tabpanel" aria-labelledby="profile-tab">
          {(() => {         
              if (doc === 0) {
                  return (
                    <div className="row justify-content-end">
        <div className="col-xl-2 col-lg-3 col-sm-12 col-md-4 col-xs-6 col-btn">
                  <Link to={"/document"} style={{textDecoration:"none"}}>
                    <button className="btn-pwd">
                      <h5 className="text-pwd">
                        {file}  Add Document
                      </h5>
                    </button>
                  </Link>
                </div>
                </div>
                  )}else if (doc !== 0) {
                    return (
                      <div className="row justify-content-center row-data">
                        
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-data">
                    <div className="card card-data">
                      <h5 className="title-data">Document</h5>
                        <div className="card-body">
                        {/* ROW 1 */}
                        <div class="mb-2 row row-data">

                        {/* CV */}
                        <label className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                          CV (Curriculum Vitae)
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.cv}`} target="_blank">: {datadoc.cv}</a></h5>
                        </div>

                        {/* Surat Lamaran Kerja*/}
                        <label
                        for="suratLamaran"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Surat Lamaran Kerja 
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.lamaran}`} target="_blank">: {datadoc.lamaran}</a></h5>
                        </div> 

                        </div>

                        {/* ROW 2 */}
                        <div class="mb-2 row row-data">

                        {/* Scan foto */}
                        <label
                        for="pasphoto"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Pas Photo (4x6 berwarna)
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.photo}`} target="_blank">: {datadoc.photo}</a></h5>
                        </div>

                        {/* KTP */}
                        <label
                        for="KTP"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        KTP (Kartu Tanda Penduduk)
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.ktp}`} target="_blank">: {datadoc.ktp}</a></h5>
                        </div>

                        </div>

                        {/* ROW 3 */}
                        <div class="mb-2 row row-data">

                        {/* KK */}
                        <label
                        for="KK"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Kartu Keluarga
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.kk}`} target="_blank">: {datadoc.kk}</a></h5>
                        </div>

                        {/* Ijazah/Surat Tanda Lulus */}
                        <label
                        for="Ijasah"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Ijazah/Surat Tanda Lulus
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.ijazah}`} target="_blank">: {datadoc.ijazah}</a></h5>
                        </div>

                        </div>

                        {/* ROW 4 */}
                        <div class="mb-2 row row-data">

                        {/* transkrip nilai */}
                        <label
                        for="trasnkripNilai"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Transkrip Nilai / SKHUN
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.transkrip_nilai}`} target="_blank">: {datadoc.transkrip_nilai}</a></h5>
                        </div>

                        {/* Scan skck */}
                        <label
                        for="suratVaksin"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        SKCK
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.skck}`} target="_blank">: {datadoc.skck}</a></h5>
                        </div>

                        </div>

                        {/* ROW 5 */}
                        <div class="mb-2 row row-data">

                        {/* Scan vaksin */}
                        <label
                        for="suratVaksin"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Sertifikat Vaksin
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.vaksin}`} target="_blank">: {datadoc.vaksin}</a></h5>
                        </div>

                        {/* Sertifikat Pendukung */}
                        <label
                        for="sertivikatPendukung"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Sertifikat Pendukung
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.sertipikat}`} target="_blank">: {datadoc.sertipikat}</a></h5>
                        </div>

                        </div>

                        {/* ROW 6 */}
                        <div class="mb-2 row row-data">

                        {/* SIM */}
                        <label
                        for="SIM"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        SIM A/B1/C
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.sim}`} target="_blank">: {datadoc.sim}</a></h5>
                        </div>

                        {/* Scan Surat Izin Operator / SIO */}
                        <label
                        for="suratIzinOperator"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Surat Izin Operator / SIO
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.sio}`} target="_blank">: {datadoc.sio}</a></h5>
                        </div>

                        </div>

                        {/* ROW 7 */}
                        <div class="mb-2 row row-data">

                        {/* surat parklaring */}
                        <label
                        for="suratParklaring"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        Surat Paklaring
                        <br></br>
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.paklaring}`} target="_blank">: {datadoc.paklaring}</a></h5>
                        </div>

                        {/* NPWP */}
                        <label
                        for="NPWP"
                        className="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                        >
                        NPWP
                        </label>

                        <div className="col-xl-3 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                        <h5 className="text-data"><a style={{textDecoration: "none"}} href={`https://hris.anyargroup.co.id/storage/berkaspelamar/${datadoc.npwp}`} target="_blank">: {datadoc.npwp}</a></h5>
                        </div>

                        </div>   
                        </div>
                    </div>
                  </div>
              </div>
                    );
                  } 
                })()}
                
          </div>

          <div class="tab-pane fade" id="fam" role="tabpanel" aria-labelledby="contact-tab">
          {(() => {         
              if (kel === 0) {
                  return (
                    <div className="row justify-content-end">
                    <div className="col-xl-2 col-lg-3 col-sm-12 col-md-4 col-xs-6 col-btn">
                    <Link to={`/family/${encoded}`} style={{textDecoration:"none"}}>
                      <button className="btn-pwd">
                        <h5 className="text-pwd">
                        {person}  Add Family Data
                        </h5>
                      </button>
                    </Link>
                  </div>
                  </div>
                  )}else if (kel !== 0) {
                    return (
                      <div className="row justify-content-center row-data">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-data">
                        <div className="card card-data">
                          <h5 className="title-data">Family</h5>
                          <div className="web">
                          <table class="table table-data">
                          <thead>
                            <tr>
                              <th scope="col">Family</th>
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Child</th>
                              <th scope="col">Place of Birth</th>
                              <th scope="col">Date of Birth</th>
                              <th scope="col">Age</th>
                              <th scope="col">Education</th>
                              <th scope="col">Profession</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                     <tbody>
                     {datakel.map((datakels, index) => {
            return (
              <tr key={index}>
              <td>{datakels.anggota}</td>
              <td>{datakels.nama}</td>
              <td>{datakels.jenis_kelamin}</td>
              <td>{datakels.urutan_anak}</td>
              <td>{datakels.tempat_lahir}</td>
              <td>{datakels.tanggal_lahir}</td>
              <td>{datakels.usia}</td>
              <td>{datakels.pendidikan}</td>
              <td>{datakels.pekerjaan}</td>
              <td>{datakels.keterangan}</td>
          </tr>
            );
          })}
    
                     </tbody>
                        </table>          
                        </div>
                        <div className="mob">
                          <table class="table table-data">
                          <thead>
                            <tr>
                              <th scope="col">Family</th>
                              <th scope="col">Name</th>
                              <th scope="col">Gender</th>
                              <th scope="col">Child</th>
                              <th scope="col">Place of Birth</th>
                              <th scope="col">Date of Birth</th>
                              <th scope="col">Age</th>
                              <th scope="col">Education</th>
                              <th scope="col">Profession</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                   
                     {datakel.map((datakels, index) => {
            return (
              <tr key={index}>
              <td className="content-anggota"> : {datakels.anggota}</td>
              <td className="content-nama"> : {datakels.nama}</td>
              <td className="content-jenis_kelamin"> : {datakels.jenis_kelamin}</td>
              <td className="content-urutan_anak"> : {datakels.urutan_anak}</td>
              <td className="content-tempat_lahir"> : {datakels.tempat_lahir}</td>
              <td className="content-tanggal_lahir"> : {datakels.tanggal_lahir}</td>
              <td className="content-usia"> : {datakels.usia}</td>
              <td className="content-pendidikan"> : {datakels.pendidikan}</td>
              <td className="content-pekerjaan"> : {datakels.pekerjaan}</td>
              <td className="content-keterangan"> : {datakels.keterangan}</td>
          </tr>
            );
          })}
    
                    
                        </table>          
                        </div>
                        </div>
                      </div>
                  </div>
                    );
                  } 
                })()}

       
          </div>

          <div class="tab-pane fade" id="edu" role="tabpanel" aria-labelledby="contact-tab">
            
          {(() => {         
              if (didiks === 0) {
                  return (
                    <div className="row justify-content-end">
                    <div className="col-xl-2 col-lg-3 col-sm-12 col-md-4 col-xs-6 col-btn">
                  <Link to={`/education/${encoded}`} style={{textDecoration:"none"}}>
                    <button className="btn-pwd">
                      <h5 className="text-pwd">
                      {education}  Add Euducation
                      </h5>
                    </button>
                  </Link>
                </div>
                </div>
                  )}else if (didiks !== 0) {
                    return (
                      <div className="row justify-content-center row-data">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-data">
                        <div className="card card-data">
                          <h5 className="title-data">Education</h5>
    <div className="web">
                          <table class="table table-data">
                          <thead>
                            <tr>
                              <th scope="col">Education</th>
                              <th scope="col">School name</th>
                              <th scope="col">Majoring</th>
                              <th scope="col">Place</th>
                              <th scope="col">Year of entry</th>
                              <th scope="col">Year out</th>
                              <th scope="col">GPA</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                     <tbody>
                     {datadidik.map((datadidikk, index) => {
            return (
              <tr key={index}>
              <td>{datadidikk.pendidikan}</td>
              <td>{datadidikk.institusi}</td>
              <td>{datadidikk.jurusan}</td>
              <td>{datadidikk.tempat}</td>
              <td>{datadidikk.tahun_masuk}</td>
              <td>{datadidikk.tahun_keluar}</td>
              <td>{datadidikk.nilai}</td>
              <td>{datadidikk.keterangan}</td>
          </tr>
            );
          })}
    
                     </tbody>
                        </table>          
                        </div>

                        <div className="mob">
                          <table class="table table-data">
                          <thead>
                            <tr>
                              <th scope="col">Education</th>
                              <th scope="col">School name</th>
                              <th scope="col">Majoring</th>
                              <th scope="col">Place</th>
                              <th scope="col">Year of entry</th>
                              <th scope="col">Year out</th>
                              <th scope="col">GPA</th>
                              <th scope="col">Description</th>
                            </tr>
                          </thead>
                     
                     {datadidik.map((datadidikk, index) => {
            return (
              <tr key={index}>
              <td className="content-pendidikan"> : {datadidikk.pendidikan}</td>
              <td className="content-institusi"> : {datadidikk.institusi}</td>
              <td className="content-jurusan"> : {datadidikk.jurusan}</td>
              <td className="content-tempat"> : {datadidikk.tempat}</td>
              <td className="content-tahun_masuk"> : {datadidikk.tahun_masuk}</td>
              <td className="content-tahun_keluar"> : {datadidikk.tahun_keluar}</td>
              <td className="content-nilai"> : {datadidikk.nilai}</td>
              <td className="content-keterangan"> : {datadidikk.keterangan}</td>
          </tr>
            );
          })}
    
                    
                        </table>          
                        </div>
                        </div>
                      </div>
                  </div>
                    );
                  } 
                })()}
               
          </div>
          
          <div class="tab-pane fade" id="job" role="tabpanel" aria-labelledby="contact-tab">
          {(() => {         
              if (jobs === 0) {
                  return (
                    <div className="row justify-content-end">
                    <div className="col-xl-2 col-lg-3 col-sm-12 col-md-4 col-xs-6 col-btn">
                  <Link to={`/job/${encoded}`} style={{textDecoration:"none"}}>
                    <button className="btn-pwd">
                      <h5 className="text-pwd">
                      {job}  Add Job
                      </h5>
                    </button>
                  </Link>
                </div>
                </div>
                  )}else if (jobs !== 0) {
                    return (
                      <div className="row justify-content-center row-data">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-data">
                        <div className="card card-data">
                          <h5 className="title-data">Job Experience</h5>
    <div className="web">
                          <table class="table table-data">
                          <thead>
                            <tr>
                              <th scope="col">Company</th>
                              <th scope="col">Position</th>
                              <th scope="col">Address</th>
                              <th scope="col">Date of entry</th>
                              <th scope="col">Date of out</th>
                              <th scope="col">Reason to leave</th>
                            </tr>
                          </thead>
                     <tbody>
                     {datajob.map((datajobs, index) => {
            return (
              <tr key={index}>
              <td>{datajobs.perusahaan}</td>
              <td>{datajobs.posisi}</td>
              <td>{datajobs.alamat}</td>
              <td>{datajobs.tanggal_masuk}</td>
              <td>{datajobs.tanggal_keluar}</td>
              <td>{datajobs.keterangan}</td>
          </tr>
            );
          })}
    
                     </tbody>
                        </table>          
                        </div>

                        <div className="mob">
                          <table class="table table-data">
                          <thead>
                            <tr>
                              <th scope="col">Company</th>
                              <th scope="col">Position</th>
                              <th scope="col">Address</th>
                              <th scope="col">Date of entry</th>
                              <th scope="col">Date of out</th>
                              <th scope="col">Reason to leave</th>
                            </tr>
                          </thead>
                    
                     {datajob.map((datajobs, index) => {
            return (
              <tr key={index}>
              <td className="content-perusahaan"> : {datajobs.perusahaan}</td>
              <td className="content-posisi"> : {datajobs.posisi}</td>
              <td className="content-alamat"> : {datajobs.alamat}</td>
              <td className="content-tanggal_masuk"> : {datajobs.tanggal_masuk}</td>
              <td className="content-tanggal_keluar"> : {datajobs.tanggal_keluar}</td>
              <td className="content-keterangan"> : {datajobs.keterangan}</td>
          </tr>
            );
          })}
    
                    
                        </table>          
                        </div>
                        </div>
                      </div>
                  </div>
                    );
                  } 
                })()}
                
          </div>
          
          <div class="tab-pane fade" id="med" role="tabpanel" aria-labelledby="contact-tab">
          {(() => {         
              if (kes === 0) {
                  return (
                    <div className="row justify-content-end">
                <div className="col-xl-2 col-lg-3 col-sm-12 col-md-6 col-xs-6 col-btn">
                <Link to={`/medical/${encoded}`} style={{textDecoration:"none"}}>
                  <button className="btn-pwd">
                    <h5 className="text-pwd">
                    {healt}  Add Medical
                    </h5>
                  </button>
                </Link>
              </div>
              </div>
                  )}else if (kes !== 0) {
                    return (
                      <div className="row justify-content-center row-data">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-data">
                    <div className="card card-data">
                      <h5 className="title-data">Medical history</h5>
                    <div className="web">
                      <table className="table table-data">
                      <thead className="thead-table">
                        <tr >
                          <th >Types of diseases</th>
                          <th >Disease name</th>
                          <th >Treatment</th>
                          <th >Year</th>
                          <th >Impact</th>
                        </tr>
                        </thead>
                    <tbody>
                      {datakes.map((datakes, index) => {
              return (
                <tr key={index} className="tr-table">
                <td className="content-type_penyakit">{datakes.type_penyakit}</td>
                <td className="content-nama_penyakit">{datakes.nama_penyakit}</td>
                <td className="content-penyembuhan">{datakes.penyembuhan}</td>
                <td className="content-tahun_kejadian">{datakes.tahun_kejadian}</td>
                <td className="content-akibat">{datakes.akibat}</td>
            </tr>
              );
            })}

            </tbody>
                    </table>          
                    </div>
                    <div className="mob">
                      <table className="table table-data">
                      <thead className="thead-table">
                        <tr >
                          <th >Types of diseases</th>
                          <th >Disease name</th>
                          <th >Treatment</th>
                          <th >Year</th>
                          <th >Impact</th>
                        </tr>
                        </thead>
                  
                      {datakes.map((datakes, index) => {
              return (
                <tr key={index} className="tr-table">
                <td className="content-type_penyakit"> : {datakes.type_penyakit}</td>
                <td className="content-nama_penyakit"> : {datakes.nama_penyakit}</td>
                <td className="content-penyembuhan"> : {datakes.penyembuhan}</td>
                <td className="content-tahun_kejadian"> : {datakes.tahun_kejadian}</td>
                <td className="content-akibat"> : {datakes.akibat}</td>
            </tr>
              );
            })}

          
                    </table>          
                    </div>
                    
                  </div>
                  </div>
              </div>
                    );
                  } 
                })()}
                
          </div>
        </div>
        </div>
       
         
       </div>
      
       
       
     </div>

    ):(
      <div>
      
     
     </div>
    )}
      {loader}
  </div>
  
);
}

export default Profile;
