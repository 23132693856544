import "../css/style.scss";
import "../css/document/document.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Full from './FullLoad';
import doc from "../img/listwish.png";


export default function Document() {
  const [loader, showLoader, hideLoader] = Full();
  const token = localStorage.getItem("token");
  const [isLogin, SetIsLogin] = useState(false);
  const history = useHistory();

  const [error,setError]=useState(false)

  const [errorMsgcv, setErrorMsgcv] = useState(false);
  const [errorMsglamaran, setErrorMsglamaran] = useState(false);
  const [errorMsgphoto, setErrorMsgphoto] = useState(false);
  const [errorMsgktp, setErrorMsgktp] = useState(false);
  const [errorMsgkk, setErrorMsgkk] = useState(false);
  const [errorMsgijazah, setErrorMsgijazah] = useState(false);
  const [errorMsgnilai, setErrorMsgnilai] = useState(false);
  const [errorMsgskck, setErrorMsgskck] = useState(false);
  const [errorMsgvaksin, setErrorMsgvaksin] = useState(false);
  const [errorMsgsertifikat, setErrorMsgsertifikat] = useState(false);
  const [errorMsgsim, setErrorMsgsim] = useState(false);
  const [errorMsgsio, setErrorMsgsio] = useState(false);
  const [errorMsgpaklaring, setErrorMsgpaklaring] = useState(false);
  const [errorMsgnpwp, setErrorMsgnpwp] = useState(false);


  const [isSuccesscv, setIsSuccesscv] = useState(false);
  const [isSuccesslamaran, setIsSuccesslamaran] = useState(false);
  const [isSuccessphoto, setIsSuccessphoto] = useState(false);
  const [isSuccessktp, setIsSuccessktp] = useState(false);
  const [isSuccesskk, setIsSuccesskk] = useState(false);
  const [isSuccessijazah, setIsSuccessijazah] = useState(false);
  const [isSuccessnilai, setIsSuccessnilai] = useState(false);
  const [isSuccessskck, setIsSuccessskck] = useState(false);
  const [isSuccessvaksin, setIsSuccessvaksin] = useState(false);
  const [isSuccesssertifikat, setIsSuccesssertifikat] = useState(false);
  const [isSuccesssim, setIsSuccesssim] = useState(false);
  const [isSuccesssio, setIsSuccesssio] = useState(false);
  const [isSuccesspaklaring, setIsSuccesspaklaring] = useState(false);
  const [isSuccessnpwp, setIsSuccessnpwp] = useState(false);
 

  const [datauser, setData] = useState("");


  const [idLamar, setIdLamar] = useState("");
  const [cv, setCv] = useState("");
  const [cvname, setCvname] = useState("");
  const [lamaran, setLamaran] = useState("");
  const [lamaranname, setLamaranname] = useState("");
  const [photo, setPhoto] = useState("");
  const [photoname, setPhotoname] = useState("");
  const [ktp, setKtp] = useState("");
  const [ktpname, setKtpname] = useState("");
  const [kk, setKk] = useState("");
  const [kkname, setKkname] = useState("");
  const [ijazah, setIjazah] = useState("");
  const [ijazahname, setIjazahname] = useState("");
  const [nilai, setNilai] = useState("");
  const [nilainame, setNilainame] = useState("");
  const [skck, setSkck] = useState("");
  const [skckname, setSkckname] = useState("");
  const [vaksin, setVaksin] = useState("");
  const [vaksinname, setVaksinname] = useState("");
  const [sertifikat, setSertifikat] = useState("");
  const [sertifikatname, setSertifikatname] = useState("");
  const [sim, setSim] = useState("");
  const [simname, setSimname] = useState("");
  const [sio, setSio] = useState("");
  const [sioname, setSioname] = useState("");
  const [paklaring, setPaklaring] = useState("");
  const [paklaringname, setPaklaringname] = useState("");
  const [npwp, setNpwp] = useState("");
  const [npwpname, setNpwpname] = useState("");
  


  const changeHandlercv = (event) => {
    if(event.target.files.length > 0){
      setCv(event.target.files[0]);
      setCvname(event.target.files[0].name);
    }
  };
  const changeHandlerlamaran = (event) => {
    if(event.target.files.length > 0){
      setLamaran(event.target.files[0]);
      setLamaranname(event.target.files[0].name);
    }
  };
  const changeHandlerphoto = (event) => {
    if(event.target.files.length > 0){
      setPhoto(event.target.files[0]);
      setPhotoname(event.target.files[0].name);
    }
  };
  const changeHandlerktp = (event) => {
    if(event.target.files.length > 0){
      setKtp(event.target.files[0]);
      setKtpname(event.target.files[0].name);
    }
  };
  const changeHandlerkk = (event) => {
    if(event.target.files.length > 0){
      setKk(event.target.files[0]);
      setKkname(event.target.files[0].name);
    }
  };
  const changeHandlerijazah = (event) => {
    if(event.target.files.length > 0){
      setIjazah(event.target.files[0]);
      setIjazahname(event.target.files[0].name);
    }
  };
  const changeHandlernilai = (event) => {
    if(event.target.files.length > 0){
      setNilai(event.target.files[0]);
      setNilainame(event.target.files[0].name);
    }
  };
  const changeHandlerskck = (event) => {
    if(event.target.files.length > 0){
      setSkck(event.target.files[0]);
      setSkckname(event.target.files[0].name);
    }
  };
  const changeHandlervaksin = (event) => {
    if(event.target.files.length > 0){
      setVaksin(event.target.files[0]);
      setVaksinname(event.target.files[0].name);
    }
  };
  const changeHandlersertifikat = (event) => {
    if(event.target.files.length > 0){
      setSertifikat(event.target.files[0]);
      setSertifikatname(event.target.files[0].name);
    }
  };
  const changeHandlersim = (event) => {
    if(event.target.files.length > 0){
      setSim(event.target.files[0]);
      setSimname(event.target.files[0].name);
    }
  };
  const changeHandlersio = (event) => {
    if(event.target.files.length > 0){
      setSio(event.target.files[0]);
      setSioname(event.target.files[0].name);
    }
  };
  const changeHandlerpaklaring = (event) => {
    if(event.target.files.length > 0){
      setPaklaring(event.target.files[0]);
      setPaklaringname(event.target.files[0].name);
    }
  };
  const changeHandlernpwp = (event) => {
    if(event.target.files.length > 0){
      setNpwp(event.target.files[0]);
      setNpwpname(event.target.files[0].name);
    }
  };


  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.get("https://hris.anyargroup.co.id/api/profile").then((response) => {
      setIdLamar(response.data.lamar[0])
      const formData = response.data.lamar[0].id;
      axios
        .get("https://hris.anyargroup.co.id/api/berkas/getberkas" + "/" + formData)
        .then((res) => {
          setData(res.data.data.length)
          console.log(res.data.data.length)
        });
    });


    if (!token) {
      SetIsLogin(true);
      history.push("/error");
    } else {
      SetIsLogin(false);
    }
  }, []);

  const submitFile = async (e) => {
    showLoader();
    e.preventDefault();
 
  if (!cv) {
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgcv("Please choose a file");
    setIsSuccesscv(true)
    return
  }
  if (!lamaran) {
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsglamaran("Please choose a file");
    setIsSuccesslamaran(true)
    return
  }
  if (!photo) {
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgphoto("Please choose a file");
    setIsSuccessphoto(true)
    return
  }
  if (!ktp) {
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgktp("Please choose a file");
    setIsSuccessktp(true)
    return
  }
  if (!kk) {
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgkk("Please choose a file");
    setIsSuccesskk(true)
    return
  }
  if (!ijazah) {
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgijazah("Please choose a file");
    setIsSuccessijazah(true)
    return
  }


  const MAX_FILE_SIZE = 5000 // 5MB

  const fileSizeKiloBytes = cv.size / 512
  const fileSizeKiloByteslamaran = lamaran.size / 512
  const fileSizeKiloBytesphoto = photo.size / 512
  const fileSizeKiloBytesktp = ktp.size / 512
  const fileSizeKiloByteskk = kk.size / 512
  const fileSizeKiloBytesijazah = ijazah.size / 512
  const fileSizeKiloBytesnilai = nilai.size / 512
  const fileSizeKiloBytesskck = skck.size / 512
  const fileSizeKiloBytesvaksin = vaksin.size / 512
  const fileSizeKiloBytessertifikat = sertifikat.size / 512
  const fileSizeKiloBytessim = sim.size / 512
  const fileSizeKiloBytessio = sio.size / 512
  const fileSizeKiloBytespaklaring = paklaring.size / 512
  const fileSizeKiloBytesnpwp = npwp.size / 512

   if(fileSizeKiloBytes > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgcv("File size maximum 500Kb");
    setIsSuccesscv(true)
    return
  }

  if(fileSizeKiloByteslamaran > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsglamaran("File size maximum 500Kb");
    setIsSuccesslamaran(true)
    return
  }

  if(fileSizeKiloBytesphoto > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgphoto("File size maximum 500Kb");
    setIsSuccessphoto(true)
    return
  }

  if(fileSizeKiloBytesktp > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgktp("File size maximum 500Kb");
    setIsSuccessktp(true)
    return
  }

  if(fileSizeKiloByteskk > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgkk("File size maximum 500Kb");
    setIsSuccesskk(true)
    return
  }
  if(fileSizeKiloBytesijazah > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgijazah("File size maximum 500Kb");
    setIsSuccessijazah(true)
    return
  }
  if(fileSizeKiloBytesnilai > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgnilai("File size maximum 500Kb");
    setIsSuccessnilai(true)
    return
  }
  if(fileSizeKiloBytesskck > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgskck("File size maximum 500Kb");
    setIsSuccessskck(true)
    return
  }
  if(fileSizeKiloBytesvaksin > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgvaksin("File size maximum 500Kb");
    setIsSuccessvaksin(true)
    return
  }
  if(fileSizeKiloBytessertifikat > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgsertifikat("File size maximum 1Mb");
    setIsSuccesssertifikat(true)
    return
  }
  if(fileSizeKiloBytessim > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgsim("File size maximum 500Kb");
    setIsSuccesssim(true)
    return
  }
  if(fileSizeKiloBytessio > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgsio("File size maximum 500Kb");
    setIsSuccesssio(true)
    return
  }
  if(fileSizeKiloBytespaklaring > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgpaklaring("File size maximum 1Mb");
    setIsSuccesspaklaring(true)
    return
  }
  if(fileSizeKiloBytesnpwp > MAX_FILE_SIZE){
    hideLoader();
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsgnpwp("File size maximum 500Kb");
    setIsSuccessnpwp(true)
    return
  }

  setErrorMsgcv("")
  setErrorMsglamaran("")
  setErrorMsgphoto("")
  setErrorMsgktp("")
  setErrorMsgkk("")
  setErrorMsgijazah("")
  setErrorMsgnilai("")
  setErrorMsgskck("")
  setErrorMsgvaksin("")
  setErrorMsgsertifikat("")
  setErrorMsgsim("")
  setErrorMsgsio("")
  setErrorMsgpaklaring("")
  setErrorMsgnpwp("")
  setIsSuccesscv("Validation successful")
  setIsSuccesslamaran("Validation successful")
  setIsSuccessphoto("Validation successful")
  setIsSuccessktp("Validation successful")
  setIsSuccesskk("Validation successful")
  setIsSuccessijazah("Validation successful")
  setIsSuccessnilai("Validation successful")
  setIsSuccessskck("Validation successful")
  setIsSuccessvaksin("Validation successful")
  setIsSuccesssertifikat("Validation successful")
  setIsSuccesssim("Validation successful")
  setIsSuccesssio("Validation successful")
  setIsSuccesspaklaring("Validation successful")
  setIsSuccessnpwp("Validation successful")


    const formData = new FormData();
    formData.append("id", idLamar.id);
    formData.append("cv", cv);
    formData.append("lamaran", lamaran);
    formData.append("photo", photo);
    formData.append("ktp", ktp);
    formData.append("kk", kk);
    formData.append("ijazah", ijazah);
    formData.append("transkrip_nilai", nilai);
    formData.append("skck", skck);
    formData.append("vaksin", vaksin);
    formData.append("sertipikat", sertifikat);
    formData.append("sim", sim);
    formData.append("sio", sio);
    formData.append("paklaring", paklaring);
    formData.append("npwp", npwp);
    
    await axios
      .post("https://hris.anyargroup.co.id/api/berkas/store", formData)
      .then((response) => {
        hideLoader();
        history.push(`/profile`);
        Swal.fire({
          title: 'Data input successfully',
          showConfirmButton: false,
          icon: 'info'
      })
  
      })
      .catch((err) => {
        console.log("ini error =", err.response.data)
        hideLoader();
        history.push("/document");
        Swal.fire({
          title: 'Please Cek Your Input',
          showConfirmButton: false,
          icon: 'warning'
      })
      });

    
  };

  return (
    <>
     {(() => {         
      if (datauser === 0) {
          return (
        <div>
          <div className="inputdata">
            <div className="container inputdata-label">
              <div className="label">
                <h4 className="inputdata-title">
                  <span className="inputdata-garis">Complete Data Contract</span>
                </h4>
                <label className="inputdata-title-content-1">
                    Mohon mengisi bagian yang ditandai (*) dengan lengkap
                </label>
                <br></br>
                <label className="inputdata-title-content-2">
                    Please input your information for required field (*)
                </label>
                <div className="inputdata-garis-1"></div>
              </div>

              <div className="content">
                <div className="row form-row">
                  <form encType="multipart/form-data" onSubmit={submitFile}>

                    <div className="col-12 form-col">
                      <h5 class="card-title">Upload all data contract</h5>
                        <div class="card">
                          {/* start ROW */}
                          <div class="card-body">

                            {/* ROW 1 */}
                            <div class="mb-2 row">

                              {/* CV */}
                              <label className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                                CV (Curriculum Vitae)<span className="wajib">*</span>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                <div className="input-group">
                                <input
                                  aria-label="file example"
                                  type="file"
                                  name="cv"
                                  aria-required
                                  className="form-control form-control-sm input"
                                  accept="application/pdf"
                                  onChange={changeHandlercv}
                                  style={{ display: "none" }}
                                  id="inputGroupFile01"/>
                                <input
                                  placeholder="Choose File PDF"
                                  style={{
                                    borderTopLeftRadius: "5px",
                                    borderBottomLeftRadius: "5px",
                                    borderRight: "none",
                                  }}
                                    className="form-control form-control-sm input"
                                    value={cvname}/>
                                <label htmlFor="inputGroupFile01">
                                <div
                                  className="btn btn-sm"
                                  style={{
                                    backgroundColor: "#044F9F",
                                    color: "#fff",
                                    borderTopLeftRadius: "0px",
                                    borderBottomLeftRadius: "0px",
                                    borderBottomRightRadius: "5px",
                                    borderTopRightRadius: "5px",
                                  }}>
                                  Browse
                                  </div>
                                </label>
                                </div>
                                <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                               

                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgcv}
                                </div>
                            <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                              {isSuccesscv}
                                </div>
                        
                              </div>
                       
                              {/* Surat Lamaran Kerja*/}
                              <label
                         for="suratLamaran"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Surat Lamaran Kerja
                         <span className="wajib">*</span>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="lamaran"
                             aria-required
                             className="form-control form-control-sm input"
                             accept="application/pdf"
                             onChange={changeHandlerlamaran}
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile02"
                           />
                           <input
                             placeholder="Choose File PDF"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={lamaranname}
                           ></input>
                           <label htmlFor="inputGroupFile02">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>

                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsglamaran}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccesslamaran}
                         </div>
                              </div>

                            </div>
                     
                            {/* ROW 2 */}
                            <div class="mb-2 row">

                              {/* Scan foto */}
                              <label
                         for="pasphoto"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Pas Photo (4x6 berwarna)
                         <span className="wajib">*</span>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="pas_photo"
                             aria-required
                             className="form-control form-control-sm input"
                             accept="application/pdf"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile03"
                             onChange={changeHandlerphoto}
                           />
                           <input
                             placeholder="Choose File PDF"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={photoname}
                           ></input>
                           <label htmlFor="inputGroupFile03">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>

                        <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgphoto}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessphoto}
                         </div>
                              </div>
                       
                              {/* KTP */}
                              <label
                         for="KTP"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         KTP (Kartu Tanda Penduduk)
                         <span className="wajib">*</span>
                         <br></br>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="ktp"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile04"
                             onChange={changeHandlerktp}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={ktpname}
                           ></input>
                           <label htmlFor="inputGroupFile04">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgktp}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessktp}
                         </div>
                              </div>

                            </div>

                            {/* ROW 3 */}
                            <div class="mb-2 row">

                            {/* KK */}
                            <label
                         for="KK"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Kartu Keluarga
                         <span className="wajib">*</span>
                            </label>

                            <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="kk"
                             aria-required
                             className="form-control form-control-sm input"
                             accept="application/pdf"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile05"
                             onChange={changeHandlerkk}
                           />
                           <input
                             placeholder="Choose File PDF"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={kkname}
                           ></input>
                           <label htmlFor="inputGroupFile05">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgkk}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccesskk}
                         </div>
                            </div>
                       
                            {/* Ijazah/Surat Tanda Lulus */}
                            <label
                         for="Ijasah"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Ijazah/Surat Tanda Lulus
                         <span className="wajib">*</span>
                         <br></br>
                            </label>

                            <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="ijasah"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile06"
                             onChange={changeHandlerijazah}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={ijazahname}
                           ></input>
                           <label htmlFor="inputGroupFile06">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgijazah}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessijazah}
                         </div>
                            </div>

                            </div>
                     
                            {/* ROW 4 */}
                            <div class="mb-2 row">

                              {/* transkrip nilai */}
                              <label
                         for="trasnkripNilai"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Transkrip Nilai / SKHUN (Jika ada)
                         <br></br>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="transkrip_nilai"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile07"
                             onChange={changeHandlernilai}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={nilainame}
                           ></input>
                           <label htmlFor="inputGroupFile07">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgnilai}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessnilai}
                         </div>
                              </div>
                       
                              {/* Scan skck */}
                              <label
                         for="suratVaksin"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                        SKCK (Jika ada)
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="skck"
                             aria-required
                             className="form-control form-control-sm input"
                             accept="application/pdf"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile08"
                             onChange={changeHandlerskck}
                           />
                           <input
                             placeholder="Choose File PDF"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={skckname}
                           ></input>
                           <label htmlFor="inputGroupFile08">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgskck}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessskck}
                         </div>
                              </div>

                            </div>

                            {/* ROW 5 */}
                            <div class="mb-2 row">

                              {/* Scan vaksin */}
                              <label
                         for="suratVaksin"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Sertifikat Vaksin (min. Dosis 2) (Jika ada)
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="vaksin"
                             aria-required
                             className="form-control form-control-sm input"
                             accept="application/pdf"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile09"
                             onChange={changeHandlervaksin}
                           />
                           <input
                             placeholder="Choose File PDF"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={vaksinname}
                           ></input>
                           <label htmlFor="inputGroupFile09">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgvaksin}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessvaksin}
                         </div>
                              </div>
                       
                              {/* Sertifikat Pendukung */}
                              <label
                         for="sertivikatPendukung"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Sertifikat Pendukung
                         <br></br>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="sertifikat_pendukung"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile10"
                             onChange={changeHandlersertifikat}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={sertifikatname}
                           ></input>
                           <label htmlFor="inputGroupFile10">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 1Mb (Pdf)</span>
                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgsertifikat}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccesssertifikat}
                         </div>
                              </div>

                            </div>

                            {/* ROW 6 */}
                            <div class="mb-2 row">

                              {/* SIM */}
                              <label
                         for="SIM"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         SIM A/B1/C(Khusus Driver/supir)
                         <br></br>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="sim"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile11"
                             onChange={changeHandlersim}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={simname}
                           ></input>
                           <label htmlFor="inputGroupFile11">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgsim}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccesssim}
                         </div>
                              </div>

                              {/* Scan Surat Izin Operator / SIO */}
                              <label
                         for="suratIzinOperator"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Surat Izin Operator / SIO
                         <br></br>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="surat_izin_operator"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile12"
                             onChange={changeHandlersio}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={sioname}
                           ></input>
                           <label htmlFor="inputGroupFile12">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgsio}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccesssio}
                         </div>
                              </div>
                       
                            </div>

                            {/* ROW 7 */}
                            <div class="mb-2 row">

                              {/* surat parklaring */}
                              <label
                         for="suratParklaring"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         Surat Paklaring (Jika ada)
                         <br></br>
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="parklaring"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile13"
                             onChange={changeHandlerpaklaring}
                           />
                           <input
                             placeholder="Choose file Pdf"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={paklaringname}
                           ></input>
                           <label htmlFor="inputGroupFile13">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 1Mb (Pdf)</span>
                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgpaklaring}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccesspaklaring}
                         </div>
                              </div>
                       
                              {/* NPWP */}
                              <label
                         for="NPWP"
                         className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                       >
                         NPWP (Jika ada)
                              </label>

                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                         <div className="input-group">
                           <input
                             aria-label="file example"
                             type="file"
                             name="npwp"
                             aria-required
                             className="form-control form-control-sm input"
                             accept="application/pdf"
                             data-classButton="btn btn-primary"
                             style={{ display: "none" }}
                             id="inputGroupFile14"
                             onChange={changeHandlernpwp}
                           />
                           <input
                             placeholder="Choose File PDF"
                             style={{
                               borderTopLeftRadius: "5px",
                               borderBottomLeftRadius: "5px",
                               borderRight: "none",
                             }}
                             className="form-control form-control-sm input"
                             value={npwpname}
                           ></input>
                           <label htmlFor="inputGroupFile14">
                             <div
                               className="btn btn-sm"
                               style={{
                                 backgroundColor: "#044F9F",
                                 color: "#fff",
                                 borderTopLeftRadius: "0px",
                                 borderBottomLeftRadius: "0px",
                                 borderBottomRightRadius: "5px",
                                 borderTopRightRadius: "5px",
                               }}
                             >
                               Browse
                             </div>
                           </label>
                         </div>
                         <span style={{fontSize: "11px", color: "green"}}>Max 500Kb (Pdf)</span>
                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                             {errorMsgnpwp}
                         </div>

                         <div style={{color:"green", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {isSuccessnpwp}
                         </div>
                              </div>

                            </div>

                          </div>
                          {/* END ROW */}
                        </div>
                    </div>

                    <div className="col-12 form-button">
                 <center>
                  
                   <button type="submit" className="button-inputdata">
                     <a className="inputdata">Upload</a>
                   </button>
                 </center>
                    </div>

                  </form>
                </div>
              </div>
            </div>
            {loader}
          </div>
          
        </div>
      )
      }else if (datauser !== 0) {
        return (  
          <div>
            <div style={{marginTop:"250px", textAlign:"center", fontSize:"25px"}}>
              <img src={doc}></img>
              </div>
              <h5 style={{ textAlign:"center", fontSize:"25px"}}>Data Has Been Added</h5>
          </div>
      );
      } 
      })()}
    </>
  );
}
