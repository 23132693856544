import "../css/style.scss";
import "../css/status/status.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import Full from './FullLoad';
import status_vacancy from "../img/status_vacancy.png";
import status_admin from "../img/administrasi.png";
import status_psikotes from "../img/mood.png";
import status_interview from "../img/interview_2.png";
import status_person from "../img/person_inter.png";
import status_contract from "../img/contract.png";
import { useParams } from "react-router-dom";
import Moment from 'moment';


function Status() {
  const [status1, setStatus] = useState([]);
  const [user, setUser] = useState({});
  const [loker, setLoker] = useState([]);
  const [loader, showLoader, hideLoader] = Full();
  const parse = require("html-react-parser");
  const token = localStorage.getItem("token");
  const params = useParams();


  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
   axios.get(`https://hris.anyargroup.co.id/api/profile/`)
    .then((response) => {
      setUser(response.data.lamar[0]);
      setLoker( response.data.apply);
      console.log("profile",response.data)
      hideLoader();
    });
  }, []);

  useEffect(() => {
    showLoader();
    axios
      .get(`https://hris.anyargroup.co.id/api/status/apply/${params.id}`)
      .then((res) => {
        setStatus(res.data.data);
        console.log(res.data.data)
        hideLoader();
      })
      .catch((err) => {
      });

  }, [params.id]);


return (
  <>
           <div>
           <div className="status">
                <div className="container container-status">
                  <div className="row justify-content-center row-status ">
                    <div className="col-lg-3 col-md-9 col-sm-12 col-xs-12 col-status">
                      <img src={status_vacancy} alt="Hria" className="status-image" />
                      <h5 className="status-title">Status Vacancy</h5>
                    </div>
                      <div class="row row-text justify-content-center">
                        <div class="col-xl-12 col-lg-8 col-text">
                          <p class="text-muted">
                            Halo <span className="user">{user.nama_lengkap}</span> your vacancy <span className="user"></span> on progress
                         
                          </p>
                        </div>
                      </div>
                  </div>
          
                  <div className="row row-step-bulat">
                    <div className="col-lg-2 col-md-2 col-bulat">
                    <div class="card card-step">
                    <div class="indicator-line active"></div>
                    <img src={status_admin} className="admin" />
                      </div>  
                    </div>
                    <div className="col-lg-2 col-md-2 col-bulat">
                    <div class="card card-step">
                    <div class="indicator-line active"></div>
                    <img src={status_psikotes} className="psikotes" />
                      </div>  
                    </div>
                    <div className="col-lg-2 col-md-2 col-bulat">
                    <div class="card card-step">
                    <div class="indicator-line active"></div>
                    <img src={status_interview} className="interview" />
                      </div>  
                    </div>
                    <div className="col-lg-2 col-md-2 col-bulat">
                    <div class="card card-step">
                    <div class="indicator-line active"></div>
                    <img src={status_person} className="interview1" />
                      </div>  
                    </div>
                    <div className="col-lg-2 col-md-2 col-bulat">
                    <div class="card card-step">
                    <div class="indicator-line active"></div>
                    <img src={status_contract} className="offering" />
                      </div>  
                    </div>
                    <div className="col-lg-2 col-md-2 col-bulat">
                    <div class="card card-step">
                    <img src={status_psikotes} className="contract" />
                      </div>  
                    </div>
                  </div>
          
                  <div className="row row-step-content">
                  {status1&&status1.slice(0,6).map((status1s) => (
                    
                    <div className="col-lg-2 col-md-2 col-step-content" key={status1s.id}>
                      {/* {status1s.loker.lowongan_kerja} */}
                       {(() => {
                                    if (status1s.status == "Approved") {
                                      return (
                                        <div>
                                          <div className="card card-step-content-acc"> </div>
                                          <h5 className="title1-acc">{status1s.progres}</h5>
                                          <h5 className="title2-acc">Status : {status1s.status}</h5>
                                          <h5 className="title3-acc">{parse(status1s.note)}</h5>
                                          <h5 className="title3-acc">{Moment(status1s.waktu).format('DD-MM-YYYY') }</h5>
                                        </div>
                                      );
                                    } else if (status1s.status == "Rejected") {
                                      return (
                                        <div>
                                          <div className="card card-step-content-reject"> </div>
                                          <h5 className="title1-reject">{status1s.progres}</h5>
                                          <h5 className="title2-reject">Status : {status1s.status}</h5>
                                          <h5 className="title3-reject">{parse(status1s.note)}</h5>
                                          <h5 className="title3-reject">{Moment(status1s.waktu).format('DD-MM-YYYY') }</h5>
                                        </div>
                                      );
                                    } else if (status1s.status == "In Process") {
                                      return (
                                
                                           (() => {
                                    if (status1s.progres == "Administration") {
                                      return (
                                        <div>
                                          <div className="card card-step-content-proses"> </div>
                                          <h5 className="title1-proses">{status1s.progres}</h5>
                                          <h5 className="title2-proses">Status : {status1s.status}</h5>
                                          <h5 className="title3-proses"> {Moment(status1s.waktu).format('DD-MM-YYYY') } </h5>
                                        </div>
                                      );
                                    } else if (status1s.progres !== "Administration") {
                                      return (
                                        <div>
                                          <div className="card card-step-content-proses"> </div>
                                          <h5 className="title1-proses">{status1s.progres}</h5>
                                          <h5 className="title2-proses">Status : {status1s.status}</h5>
                                          <h5 className="title3-proses">Tanggal Datang</h5>
                                          <h5 className="title3-proses"> {Moment(status1s.waktu).format('DD-MM-YYYY') }</h5>
                                        </div>
                                      );
                                    } 
                                
                                  })( )
                                          
                                     
                                      );
                                    }
                                
                                  })( )}
                    </div>
                    ))}
                  </div>

                  <div className="row row-step-content-mob">
                  <div className="container-content">
                    <div className="table table-content">
                        <div className="thead-content">
                        {status1&&status1.slice(0,7).map((status1s) => (
                          <tr className="tr-content">
                            {(() => {
                                    if (status1s.status === "Approved") {
                                      return (
                                        <div className="approve">
                                          <th className="th-content-ikon">   
                                            <div class="card card-content-approved"></div> 
                                          
                                          </th>
                                          <th className="th-content-desk-approved">
                                          <h5 className="title0-approved">-</h5>
                                          <h5 className="title1-approved">{status1s.progres} || {Moment(status1s.waktu).format('DD-MM-YYYY') } </h5>
                                          <h5 className="title2-approved">Status : {status1s.status}</h5>
                                          <text className="title3-approved">{parse(status1s.note)}</text>
                                          
                                          </th>
                                          {/* <div className="icon"></div> */}
                                        </div>
                                      );
                                    } else if (status1s.status === "Rejected") {
                                      return (
                                        <div className="reject">
                                          <th className="th-content-ikon">   
                                            <div class="card card-content-rejected"></div>
                                          </th>
                                          
                                          <th className="th-content-desk-rejected">
                                            <h5 className="title0-rejected">aa</h5>
                                            <h5 className="title1-rejected">{status1s.progres} || {Moment(status1s.waktu).format('DD-MM-YYYY') } </h5>
                                            <h5 className="title2-rejected">Status : {status1s.status}</h5>
                                            <h5 className="title3-rejected">{parse(status1s.note)}</h5>
                                          </th>
                                        </div>
                                      );
                                    } else if (status1s.status === "In Process") {
                                      return (
                                        <div>
                                                   {(() => {
                                    if (status1s.progres === "Finish") {
                                      return (
                                        <div className="finish">
                                        <th className="th-content-ikon">   
                                         <div class="card card-content-finish"></div>
                                       </th>
                                       <th className="th-content-desk-finish">
                                       <h5 className="title0-finish">aa</h5>
                                       <h5 className="title1-finish">{status1s.progres}</h5>
                                       {/* <h5 className="title2-process">Status : {status1s.status}</h5> */}
                                       
                                       </th>

                                     
                                   </div>
                                      );
                                    } else if (status1s.status === "In Process") {
                                      return (
                                        
                                        (() => {
                                          if (status1s.progres == "Administration") {
                                            return (
                                              <div className="process">
                                              <th className="th-content-ikon">   
                                               <div class="card card-content-process"></div>
                                             </th>
                                             <th className="th-content-desk-process">
                                             <h5 className="title0-process">aa</h5>
                                             <h5 className="title1-process">{status1s.progres} || {Moment(status1s.waktu).format('DD-MM-YYYY') } </h5>
                                             <h5 className="title2-process">Status : {status1s.status}</h5>
                                             
                                             </th>
      
                                           
                                         </div>
                                            );
                                          } else if (status1s.progres !== "Administration") {
                                            return (
                                              <div className="process">
                                              <th className="th-content-ikon">   
                                               <div class="card card-content-process"></div>
                                             </th>
                                             <th className="th-content-desk-process">
                                             <h5 className="title0-process">aa</h5>
                                             <h5 className="title1-process">{status1s.progres}  </h5>
                                             <h5 className="title2-process">Status : {status1s.status}</h5>
                                             <h5 className="title3-process">Tanggal Datang : {Moment(status1s.waktu).format('DD-MM-YYYY') } </h5>
                                             
                                             </th>
      
                                           
                                         </div>
                                            );
                                          } 
                                      
                                        })( )
                                     
                                      );
                                    } 
                                
                                  })( )}
                                     
                                        </div>
                                       
                                      );
                                    } 
                                
                                  })( )}
                          </tr>
                      ))}
                          
                        </div>
                    </div>
                    </div>

                  </div>

                </div>
              </div>


          

{loader}
      </div>
     
       </>
        );
    
 
}

export default Status;

