import "../css/style.scss";
import "../css/datapribadi/datapribadi.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Full from './FullLoad';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useParams, Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
const plus = <FontAwesomeIcon icon={faPlus} />;
const minus = <FontAwesomeIcon icon={faMinus} />;

export default function Datapribadi() {
  const token = localStorage.getItem("token");

  const params = useParams();

  const history = useHistory();
  const [isLogin, SetIsLogin] = useState(false);
  const [loader, showLoader, hideLoader] = Full();

  let decoded = base64_decode(params.encoded);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    if (!token) {
      SetIsLogin(true);
      history.push("/error");
    } else {
      SetIsLogin(true);
    }
  }, []);
  


  const [inputFields, setInputFields] = useState([{
    fk_pelamar: decoded
  }]);

  const handleFormChange = (index, event) => {
   let data = [...inputFields];
   data[index][event.target.id] = event.target.value;
   setInputFields(data);
  }



  const tambah = () => {
   let newfield = ({
    fk_pelamar: decoded
   })
   setInputFields([...inputFields, newfield])
  }

  const hapus = (index) => {
   let data = [...inputFields];
   data.splice(index, 1)
   setInputFields(data)
  }
  
   const submit = async (e) => {
    showLoader();
    e.preventDefault();
    axios({
      method: 'post',
      url: 'https://hris.anyargroup.co.id/api/pendidikan/insert',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: inputFields
     
  })
 
      .then((response) => {
        hideLoader()
        Swal.fire({
          title: 'Input Data Success',
          showConfirmButton: false,
          icon: 'success'
      })
      history.push("/profile");
      })
      .catch((err) => {
        hideLoader()
        Swal.fire({
          title: 'Please check your input',
          showConfirmButton: false,
          icon: 'warning'
      })
      });
   }

  return (
    <>
     {isLogin ? (
      <>
       <div className="datapribadi">
       <div className="container datapribadi-label">
         <div className="label">
           <h4 className="datapribadi-title">
             <span className="datapribadi-garis">Personal Data </span>
           </h4>
           <label className="datapribadi-title-content-1">
             Lengkapi form yang ada di bawah ini, sesuai data pribadi anda
           </label>
           <br></br>
           <label className="datapribadi-title-content-2">
             Complete the form below, according to your personal data
           </label>
           
           <div className="datapribadi-garis-1"></div>
         </div>
         </div>

       <div className="container datapribadi-content">
         <div className="content">
           <div className="row form-row">
             <form encType="multipart/form-data" onSubmit={submit} className="form-datapribadi">

               {/* Form pendidikan */}
               <div className="col-12 form-col">
                 <h5 class="card-title">Education and training history</h5>
                 <div class="card">
                   <div class="card-body">
                   <div className="btn btn-sm btn-add" onClick={tambah}>{plus}</div>
                   <div className="content-table">
               
                       <table  className="table table-borderless table-content">
                               <thead className="head-table-head">
                                 <tr className="head-table">
                                   <th className="riwayat-pendidikan">Education</th>
                                   <th className="riwayat-sekolah">School name</th>
                                   <th className="riwayat-jurusan">Majoring</th>
                                   <th className="riwayat-tempat">Place</th>
                                   <th className="riwayat-masuk">Year of entry</th>
                                   <th className="riwayat-keluar">Year out</th>
                                   <th className="riwayat-nilai">GPA</th>
                                   <th className="riwayat-keterangan">Description</th>
                                   
                                 </tr>
                               </thead>
                             {inputFields.map((input, index) => {
                                 return (
                                 <tbody key={index} className="body-table-body">
                                   <tr className="body-table">
   
                                 <td className="content-riw-pendidikan">
                                   <input className="form form-control form-control-sm" placeholder="Input education" name="pendidikan" id="pendidikan" type="text" value={input.pendidikan}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-sekolah">
                                   <input className="form form-control form-control-sm " placeholder="Input school name" name="institusi" id="institusi"  type="text" value={input.institusi}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-jurusan">
                                   <input className="form form-control form-control-sm " placeholder="Input majoring" name="jurusan" id="jurusan" type="text" value={input.jurusan}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-tempat">
                                   <input className="form form-control form-control-sm " placeholder="Input place" name="tempat" id="tempat" type="text" value={input.tempat}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-masuk">
                                   <input className="form form-control form-control-sm " maxLength={4} placeholder="Input year of entry" name="tahun_masuk" id="tahun_masuk" type="text" value={input.tahun_masuk}
                                   onChange={(event) =>{
                                      let re = /^[0-9\-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-keluar">
                                   <input className="form form-control form-control-sm " maxLength={4} placeholder="Input year of out" name="tahun_keluar" id="tahun_keluar" type="text" value={input.tahun_keluar}
                                   onChange={(event) =>{
                                      let re = /^[0-9\-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-keluar">
                                   <input className="form form-control form-control-sm " maxLength={4} placeholder="Input Gpa Ex: 4.00" name="nilai" id="nilai" type="text" value={input.nilai}
                                   onChange={(event) =>{
                                      let re = /^[0-9\-\.\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>

                                 <td className="content-riw-tempat">
                                 <select className="form form-control form-control-sm" name="keterangan" id="keterangan" value={input.keterangan} onChange={event => handleFormChange(index, event)}>
                                       <option selected disabled value="">Choose description</option>
                                      <option value="lulus">Lulus</option>
                                      <option value="belum lulus">Belum Lulus</option>
                                   </select>
                                 </td> 

                                 <td>
                                 <button className="btn btn-sm btn-hapus" disabled={index === 0} onClick={() => hapus(index)}><span className="icon">{minus}</span></button>
                                 </td>
                                 
                                
                                   </tr>
                                 </tbody>
                                );
                               })}
                       </table>
                       <span className="note">Note</span><br></br>
                   <span className="note">1. Keterangan di isi lulus atau belum lulus.</span><br></br>
                   <span className="note">2. Isi <b>(-)</b> untuk kolom yang tidak perlu.</span>
                   </div>
                   

                  
                   </div>
                 </div>
               </div>
               {/* END Form */}

   

               <div className="col-12 form-button">
                 <center>
                   <button type="submit" className="button-inputdata">
                     <a className="inputdata">Submit</a>
                   </button>
                 </center>
               </div>
             </form>
             
            
           </div>
         </div>

       </div>

     </div>
     {loader}
     </>
     
     ):(
      <h5>data readyu</h5>
     )}
     
    </>
  );
}
