import "../css/style.scss";
import "../css/editprofile/editprofile.scss";
import profile from "../img/profile";
import update from "../img/update.png";
import close from "../img/close.png";
import gembok from "../img/gembok.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Full from './FullLoad';
import Swal from "sweetalert2"; 

function EditProfile() {
  const history = useHistory();
  const [error,setError]=useState(false)
  const [nama_lengkap, setName] = useState("");
  const [nik, setNik] = useState("");
  const [gender, setGender] = useState("");
  const [tgl_lahir, setTgllahir] = useState("");
  const [tempat_lahir, setTempatlahir] = useState("");
  const [status_pernikahan, setStatus] = useState("");
  const [agama, setAgama] = useState("");
  const [photo, setPhoto] = useState("");
  const [cv, setCv] = useState("");
  const [alamat, setAlamat] = useState("");
  const [rt, setRt] = useState("");
  const [rw, setRw] = useState("");
  const [desa, setDesa] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kota, setKota] = useState("");
  const [kodepos, setKodepos] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [status_rumah, setStatusrumah] = useState("");
  const [no_telp, setNotelp] = useState("");
  const [no_hp, setNohp] = useState("");
  const [pendidikan_terakhir, setPendidikanterakhir] = useState("");
  const [tahun_masuk, setTahunmasuk] = useState("");
  const [nama_sekolah, setNamasekolah] = useState("");
  const [tahun_lulus, setTahunlulus] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [ipk, setIpk] = useState("");
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState("");
  const [imgData, setImgData] = useState(null);
  const [hub, setHubungan] = useState("");
  const [cvname, setCvname] = useState("");

  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg1, setErrorMsg1] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [isLogin, SetIsLogin] = useState(false);
  const [profile, setProfile] = useState ([]);  
  const token = localStorage.getItem("token");
  const [loader, showLoader, hideLoader] = Full();

  const onChangePicture = (event) => {
    if (event.target.files.length > 0) {
        setPhoto(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const changeHandlerpdf = (event) => {
      setCv(event.target.files[0]);
      setCvname(event.target.files[0].name);
  }; 

  const fetchData = async () => {
  showLoader();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios.get("https://hris.anyargroup.co.id/api/profile").then((response) => {
      setProfile(response.data.data); 
      if (response.data.lamar[0]) {
       setUser(response.data.lamar[0]);
        setNik(response.data.lamar[0].nik);
        setName(response.data.lamar[0].nama_lengkap);
        setTgllahir(response.data.lamar[0].tgl_lahir);
        setTempatlahir(response.data.lamar[0].tempat_lahir);
        setNohp(response.data.lamar[0].no_hp);
        setAgama(response.data.lamar[0].agama);
        setAlamat(response.data.lamar[0].alamat);
        setRt(response.data.lamar[0].rt);
        setRw(response.data.lamar[0].rw);
        setDesa(response.data.lamar[0].desa);
        setKecamatan(response.data.lamar[0].kecamatan);
        setKota(response.data.lamar[0].kota);
        setKodepos(response.data.lamar[0].kodepos);
        setProvinsi(response.data.lamar[0].provinsi);
        setPendidikanterakhir(response.data.lamar[0].pendidikan_terakhir);
        setTahunmasuk(response.data.lamar[0].tahun_masuk);
        setNamasekolah(response.data.lamar[0].nama_sekolah);
        setTahunlulus(response.data.lamar[0].tahun_lulus);
        setJurusan(response.data.lamar[0].jurusan);
        setIpk(response.data.lamar[0].ipk);
        setCv(response.data.lamar[0].cv);
        setUserId(response.data.lamar[0].id);
        setHubungan(response.data.lamar[0].hubungan_keluarga);
        console.log("ini cv",response.data.lamar[0] )
      } else {}
    });
    hideLoader()
  };


  const createProduct = async (e) => {
    showLoader();
    e.preventDefault();
       if(nama_lengkap.length==0||nik.length==0||gender.length==0||tgl_lahir.length==0||tempat_lahir.length==0
      ||status_pernikahan.length==0||agama.length==0||cv.length==0||photo.length==0||alamat.length==0||rt.length==0
      ||rw.length==0||desa.length==0||kecamatan.length==0||kota.length==0||kodepos.length==0||provinsi.length==0
      ||status_rumah.length==0||no_telp.length==0||no_hp.length==0||pendidikan_terakhir.length==0||tahun_masuk.length==0||nik.length==0
      ||nama_sekolah.length==0||tahun_lulus.length==0||jurusan.length==0||ipk.length==0){
      setError(true)
  }

  const MAX_FILE_SIZE = 2048
  const MAX_FILE_SIZEPDF = 5000 // 5MB
  const fileSizeKiloBytes = photo.size / 1024
  const fileSizeKiloBytes1 = cv.size / 1048

  if(fileSizeKiloBytes > MAX_FILE_SIZE){
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsg("File size maximum 2Mb");
    setIsSuccess(false);  
    hideLoader()
    return
  }

  if(fileSizeKiloBytes1 > MAX_FILE_SIZEPDF){
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    setErrorMsg1("File size maximum 5Mb");
    hideLoader()
    return
  }

  setErrorMsg("")
  setErrorMsg1("")
  setIsSuccess(true)

    const formData = new FormData();
    formData.append("photo", photo);
    formData.append("nama_lengkap", nama_lengkap);
    formData.append("nik", nik);
    formData.append("no_hp", no_hp);
    formData.append("no_telp", no_telp);
    formData.append("tgl_lahir", tgl_lahir);
    formData.append("agama", agama);
    formData.append("gender", gender);
    formData.append("tempat_lahir", tempat_lahir);
    formData.append("status_pernikahan", status_pernikahan);
    formData.append("cv", cv);
    formData.append("alamat", alamat);
    formData.append("rt", rt);
    formData.append("rw", rw);
    formData.append("desa", desa);
    formData.append("kecamatan", kecamatan);
    formData.append("kota", kota);
    formData.append("provinsi", provinsi);
    formData.append("kodepos", kodepos);
    formData.append("status_rumah", status_rumah);
    formData.append("pendidikan_terakhir", pendidikan_terakhir);
    formData.append("nama_sekolah", nama_sekolah);
    formData.append("jurusan", jurusan);
    formData.append("tahun_masuk", tahun_masuk);
    formData.append("tahun_lulus", tahun_lulus);
    formData.append("ipk", ipk);
    formData.append("hubungan_keluarga", hub);
    await axios
      .post(`https://hris.anyargroup.co.id/api/pelamar/updateprofile/${userId}`, formData)
      .then((response) => {
        history.push("/profile");
        Swal.fire({
            title: 'Data Successfully Update',
            showConfirmButton: false,
            icon: 'info'
        })
        hideLoader()
      })
      .catch((error) => {
        Swal.fire({
            title: 'Please check your input',
            showConfirmButton: false,
            icon: 'warning'
        })
        hideLoader()
      });
  };

  useEffect(() => {
    if (token) {
      SetIsLogin(true);
    } else {
      SetIsLogin(false);
    }
    fetchData();
  }, []);

return (
  <div className="edit-profile">
  {isLogin ? ( 
      <form enctype="multipart/form-data" onSubmit={createProduct}  >
          <div className="container container-edit-profile">
            <div className="row justify-content-center row-edit-profile">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-3 col-img">
              <div className="bord"></div>
                <div className="picture">
                <div className="bord1">             
               </div>
                  <img src={`https://hris.anyargroup.co.id/storage/pelamar/${user.photo}`} className="img-profile"></img>
                </div>
              </div>

              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-3 col-img1">
              <div className="bord">
                
              </div>
                <div className="picture">
                <div className="bord1"> </div>
                <img className="img-profile1" src={imgData} />
              
                </div>
   
                {isSuccess ? <p className="success-message">Validation successful</p> : null} 
                <div style={{color:"red", fontSize:"14px", textAlign: "center", marginTop:"-50px", marginBottom:"30px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                        <p className="error-message">{errorMsg}</p>
                </div>     
                <input id="file-upload"  style={{display: "none"}} name="photo" type="file" onChange={onChangePicture} />
              </div>
            
            </div>
            <label for="file-upload" style={{ 
                   backgroundColor:"#1572A1", fontSize:"14px", height:"35px", width:"150px",color:"white", display: "block", marginLeft: "auto", 
                  marginRight:"auto", marginTop:"-85px",marginBottom:"30px", textAlign:"center", zIndex:"-999"}} >
              Upload</label>

            <div className="row justify-content-center row-data">

              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-data">
                <div className="card card-data">
                  <div className="container container-data" >
                    <h5 className="title-data">Personal Data</h5>
                 
                      <div className="col-xl-12 col-input">
                        <label className="titel">NIK</label>
                        <input
                          type="number"
                          name="NIK"
                          readOnly
                          value={nik}
                          className="form-control form-control-sm input"></input>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Full Name</label>
                      <input
                          type="text"
                          name="name"
                          placeholder="Please input Full name"
                          value={nama_lengkap}
                          onChange={(e)=>{setName(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                        <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {error&&nama_lengkap.length<=0?<label>Full Name can't be Empty</label>:""}
                        </div>
                      </div>

                      <div class="row">
                        <div className="col-xl-6 col-input">
                        <label className="titel">City Of Birth</label>
                        <input
                          type="text"
                          name="tempat_lahir"
                          value={tempat_lahir}
                          readOnly
                          onChange={(e)=>{setTempatlahir(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                        </div>

                        <div className="col-xl-6 col-input">
                        <label className="titel">Date Of Birth</label>
                        <input
                            type="date"
                            name="date"
                            value={tgl_lahir}
                            readOnly
                            onChange={(e)=>{setTgllahir(e.target.value)}}
                            className="form-control form-control-sm input"></input>
                        </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">E-mail</label>
                      <input
                          type="email"
                          name="email"
                          value={profile.email}
                          readOnly
                          className="form-control form-control-sm input"></input>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Phone</label>
                      <input
                          type="number"
                          name="Phone number"
                          readOnly
                          value={no_hp}
                          className="form-control form-control-sm input" ></input>
                      </div>
            
                      <div className="col-xl-12 col-input">
                      <label className="titel">Documents <span style={{fontSize: "11px",color:"green"}}>Max 5Mb (Pdf)</span></label>
                      <div className="input-group">
                          <input
                             type="file"
                             name="cv"
                             title = "Choose a video please"
                             accept="application/pdf"
                             className="form-control form-control-sm input"
                             onChange={changeHandlerpdf}
                            style={{display:"none"}}
                            id="inputGroupFile02"
                          />
                          <input placeholder={cv} style={{borderTopLeftRadius:"5px",borderBottomLeftRadius:"5px",borderRight:"none"}}  className="form-control form-control-sm input" value={cvname}></input>
                          <label htmlFor="inputGroupFile02">
                          <div className="btn btn-sm" style={{backgroundColor:"#044F9F",color:"#fff",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px",
                           borderBottomRightRadius:"5px",borderTopRightRadius:"5px",}}>
                          Browse
                        </div>
                             </label>
                        </div>
                    
                      <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            <span className="error-message">{errorMsg1}</span>
                      </div>
                      </div>

                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-lg-6 col-md-6 col-data">
                <div className="card card-data">
                  <div className="container container-data">
                    <h5 className="title-data">Address</h5>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Address</label>
                        <input
                            type="text"
                            name="alamat"
                            placeholder="Please input Address"
                            value={alamat}
                            onChange={(e)=>{setAlamat(e.target.value)}}
                            className="form-control form-control-sm input"></input>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&alamat.length<=0?<label>Address can't be Empty</label>:""}
                          </div>
                      </div>

                      <div class="row">
                        <div className="col-xl-6 col-input">
                          <label className="titel">Rt</label>
                            <input
                              type="text"
                              name="rt"
                              placeholder="Please input Rt"
                              value={rt}
                              onChange={(e)=>{setRt(e.target.value)}}
                              className="form-control form-control-sm input"></input>
                            <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                              {error&&rt.length<=0?<label>Rt can't be Empty</label>:""}
                            </div>
                        </div>

                        <div className="col-xl-6 col-input">
                          <label className="titel">Rw</label>
                            <input
                              type="text"
                              name="rw"
                              value={rw}
                              placeholder="Please input Rw"
                              onChange={(e)=>{setRw(e.target.value)}}
                              className="form-control form-control-sm input"></input>
                            <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                {error&&rw.length<=0?<label>Rw can't be Empty</label>:""}
                            </div>
                        </div>
                      </div>

                      <div class="row">
                        <div className="col-xl-6 col-input">
                        <label className="titel">Village</label>
                          <input
                              type="text"
                              name="desa"
                              value={desa}
                              placeholder="Please input Village"
                              onChange={(e)=>{setDesa(e.target.value)}}
                              className="form-control form-control-sm input"></input>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&desa.length<=0?<label>Village can't be Empty</label>:""}
                          </div>
                        </div>

                        <div className="col-xl-6 col-input">
                        <label className="titel">District</label>
                          <input
                              type="text"
                              name="kecamatan"
                              value={kecamatan}
                              placeholder="Please input District"
                              onChange={(e)=>{setKecamatan(e.target.value)}}
                              className="form-control form-control-sm input"></input>
                          <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&kecamatan.length<=0?<label>District can't be Empty</label>:""}
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">City</label>
                      <input
                          type="text"
                          name="kota"
                          value={kota}
                          placeholder="Please input City"
                          onChange={(e)=>{setKota(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                          <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&kota.length<=0?<label>City can't be Empty</label>:""}
                          </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Postal Code</label>
                      <input
                          type="text"
                          name="kodepos"
                          value={kodepos}
                          placeholder="Please input Postal code"
                          onChange={(e)=>{setKodepos(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                    {error&&kodepos.length<=0?<label>Postal Code can't be Empty</label>:""}
                          </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Province</label>
                      <input
                          type="text"
                          name="provinsi"
                          value={provinsi}
                          placeholder="Please input Province"
                          onChange={(e)=>{setProvinsi(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                      <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {error&&provinsi.length<=0?<label>Province can't be Empty</label>:""}
                      </div>
                      </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-6 col-md-6  col-data">
                <div className="card card-data">
                  <div className="container container-data">
                    <h5 className="title-data">Education</h5>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Last Education</label>
                        <select
                            class="form-select form-select-sm input"
                            type="text"
                            value={pendidikan_terakhir}
                            name="pendidikan_terakhir"
                            aria-label=".form-select select example"
                            onChange={(e)=>{setPendidikanterakhir(e.target.value)}}>
                              <option selected disabled value="">
                                Choose Last Education
                              </option>
                              <option value="SD">SD</option>
                              <option value="SMP">SMP</option>
                              <option value="SMA">SMA</option>
                              <option value="SMK">SMK</option>
                              <option value="Diploma 1">Diploma 1</option>
                              <option value="Diploma 2">Diploma 2</option>
                              <option value="Diploma 3">Diploma 3</option>
                              <option value="Diploma 4">Diploma 4</option>
                              <option value="Strata 1">Strata 1</option>
                              <option value="Strata 2">Strata 2</option>
                              <option value="Strata 3">Strata 3</option>
                          </select>
                          <div style={{color:"red",   fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&pendidikan_terakhir.length<=0?<label>Last Education can't be Empty</label>:""}
                          </div>       
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Name Of School</label>
                      <input
                          type="text"
                          name="nama_sekolah"
                          value={nama_sekolah}
                          placeholder="Please input Name of school"
                          onChange={(e)=>{setNamasekolah(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                          <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&nama_sekolah.length<=0?<label>Name of school can't be Empty</label>:""}
                          </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Major</label>
                      <input
                          type="text"
                          name="jurusan"
                          placeholder="Please input Major"
                          value={jurusan}
                          onChange={(e)=>{setJurusan(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                          <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&jurusan.length<=0?<label>Major can't be Empty</label>:""}
                          </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">From</label>
                      <input
                          type="date"
                          name="tahun_masuk"
                          value={tahun_masuk}
                          onChange={(e)=>{setTahunmasuk(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                          <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&tahun_masuk.length<=0?<label>Start college can't be Empty</label>:""}
                          </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">Until</label>
                      <input
                          type="date"
                          name="tahun_lulus"
                          value={tahun_lulus}
                          onChange={(e)=>{setTahunlulus(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                          <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                            {error&&tahun_lulus.length<=0?<label>Graduated from college can't be Empty</label>:""}
                          </div>
                      </div>

                      <div className="col-xl-12 col-input">
                      <label className="titel">GPA</label>
                      <input
                          type="text"
                          name="ipk"
                          value={ipk}
                          maxLength={4}
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          placeholder="Please input GPA"
                          onChange={(e)=>{setIpk(e.target.value)}}
                          className="form-control form-control-sm input"></input>
                        <div style={{color:"red",  fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {error&&ipk.length<=0?<label>Gpa can't be Empty</label>:""}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
           </div>
        <hr></hr>
 
        <div className="row justify-content-center row-btn-profile">
             <div className="col-lg-3 col-xl-6 col-md-6 col-sm-6 col-xs-6 col-btn">
               <Link to={"/profile"}>
                 <button className="btn-cancel">
                   <h5 className="text-cancel">
                     <img src={close} className="img-close"></img>Cancel
                   </h5>
                 </button>
               </Link>
             </div>
             <div className="col-lg-3 col-xl-6 col-md-6 col-sm-6 col-xs-6 col-btn">
                   <button type="submit"  className="btn-update">
                     <h5 className="text-update">
                       <img src={update} className="img-update"></img>Update
                     </h5>
                   </button>
             </div>
           </div>
      
         </div>
         
      </form>
      
       ):(
        <div>anda salah</div>
       )}
    {loader}
  </div>
  );
}


export default EditProfile;

