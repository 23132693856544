import "../css/style.scss";
import "../css/wishlist/wishlist.scss";
import wishlist from "../img/listwish.png";
import Full from './FullLoad';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Wishlist() {

  const [all, setAll] = useState([]);
  const [panjang, setPanjang] = useState([]);
  const [loader, showLoader, hideLoader] = Full();

  useEffect(() => {
    showLoader();
    axios
      .get(`https://hris.anyargroup.co.id/api/wishlist`)
      .then((res) => {
        hideLoader()
        setAll(res.data.data );
        setPanjang(res.data.data.length );
      })
      .catch((err) => {  
        hideLoader()
      });
  
  }, []);

  return (
    <div className="wishlist">
      <div className="container wishlist-container">
        <div className="wishlist-title">
          <h4 className="wishlist-title-1">
            <span className="wishlist-garis">Wishlist</span>
          </h4>
        </div>
        {(() => {
            if (panjang === 0) {
              return (
                <div className="wishlist-content">
                <div className="row justify-content-center row-wishlist">
            
                  <div  className="col-xl-6 col-lg-6 col-md-6 col-wishlist">
                    <div className="dont-wish">
                    <img
                    src={wishlist}
                    className="img-wishlist"
                  ></img>
                  <h5 className="text-wishlist">You don't have wishlist</h5>
                    </div>
                  </div>
              
                </div>
                </div>
              
              );
            } if (panjang !== 0) {
              return (
                <div className="wishlist-content">
                <div className="row row-wishlist">
                {all && all.map((alls, index) => (
                  <div key={index} className="col-xl-12 col-lg-12 col-wishlist">
                    <div className="card card-wishlist">
                      <div className="row justify-content-between row-card-wishlist">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-card-wishlist">
                        <div  className="title-wish">
                              <h5 className="title-1">{alls.loker.lowongan_kerja}</h5>
                              <h5 className="title-2">
                              Job Vacancy Status : <b>{alls.loker.status}</b>
                              </h5>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-btn-wishlist">
                          <div className="row">
                           
                            <div className="col-xl-12 col-sm-12 col-md-12 col-12">
                            <Link  to={`/detail/${alls.loker_id}`} style={{ textDecoration: "none" }}>
                                  <div className="btn btn-detail">
                                 <h5 className="text-detail"> Detail</h5>
                                  </div>
                              </Link>
                            </div>
                          </div>
          
          
          
                        </div>
                      </div>
                    </div>
                  </div>
                  ))}
                </div>
                </div>
              
              );
            } })()}
   
   {loader}
      </div>
    </div>
  );
}

export default Wishlist;
