import '../css/style.scss';
import '../css/email/email.scss';
import verif from '../img/verif.png';


function Email() {

  return (
    <div className="verifikasi">

       <div className="container">
       <div className="row justify-content-center row-verifikasi ">
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-verifikasi">
         <img src={verif} alt="Hria" className="verifikasi-image"/>
         <h5 className="verifikasi-title">Cek your email</h5>
         </div>
       </div>
      
      
     </div>

     
        
        
    </div>
  );
}

export default Email;

