import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CameraFeed } from './Camera';
import Swal from "sweetalert2";  
import Full from './FullLoad';
import clock_gambar from '../img/clock.png';
import level from '../img/level.png';
import time from '../img/time.png';
import keterangan from '../img/ket.png';

function Attedance() {
const [error,setError]=useState(false)
const [loader, showLoader, hideLoader] = Full();
const [img, setImg] = useState("");
const [nik, setNik] = useState("");
const [long, setLong] = useState("");
const [lat, setLat] = useState("");
const [status, setStatus] = useState(null);
const [clock, setClock] = useState("");
const [presensis, setPresensi] = useState([]);
const presensi = presensis.sort((a, b) => (a.id < b.id) ? 1 : -1)




const handleSubmit = (e) => {
  showLoader();
    e.preventDefault();
    if(nik.length==0||img==0){setError(true)}

    const formData = new FormData();
    formData.append("image_masuk", img);
    formData.append("nik", nik);
    formData.append("longitude", long);
    formData.append("latitude", lat);
    axios.post('https://hris.anyargroup.co.id/api/presensi', formData ,{
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json'
        }
      })
    .then((response) => {
    
      if(response.data.code === "Success"){
        hideLoader();
        let timerInterval
        Swal.fire({
        title: response.data.message,
        timer: 2500,
        timerProgressBar: false,
        icon: "success",
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.reload();
        }
      })


        
    }
    
    })
    .catch((error) => {
      console.log("ini error = ", error.response)
      hideLoader();
        Swal.fire({
          title: "Silahkan Cek Inputan Anda",
          showConfirmButton: false,
          icon: "warning",
        })

      if(error.response.data.code === "Warning5"){
        hideLoader();
        let timerInterval
        Swal.fire({
        title: error.response.data.message,
        timer: 2500,
        timerProgressBar: false,
        icon: "warning",
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.reload();
        }
      })


        
    }
    if(error.response.data.code === "Warning3"){
  hideLoader();
      let timerInterval
      Swal.fire({
      title: error.response.data.message,
      timer: 2500,
      timerProgressBar: false,
      icon: "warning",
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        window.location.reload();
      }
    })
      
  } if(error.response.data.code === "Warning2"){
    hideLoader();
        let timerInterval
        Swal.fire({
        title: error.response.data.message,
        timer: 2500,
        timerProgressBar: false,
        icon: "warning",
        didOpen: () => {
          Swal.showLoading()
          const b = Swal.getHtmlContainer().querySelector('b')
          timerInterval = setInterval(() => {
            b.textContent = Swal.getTimerLeft()
          }, 100)
        },
        willClose: () => {
          clearInterval(timerInterval)
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          window.location.reload();
        }
      })
        
    } 
    if(error.response.data.code === "Warning1"){
      hideLoader();
      let timerInterval
      Swal.fire({
      title: error.response.data.message,
      timer: 2500,
      timerProgressBar: false,
      icon: "warning",
      didOpen: () => {
        Swal.showLoading()
        const b = Swal.getHtmlContainer().querySelector('b')
        timerInterval = setInterval(() => {
          b.textContent = Swal.getTimerLeft()
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        window.location.reload();
      }
    })


      
  }
    })
 
  };

const captureImage = async file => {
    setImg(file)
};


useEffect(() => {
  setInterval(() => {
      const date = new Date();
      setClock(date.toLocaleTimeString());
  },1000)

fetchData();
getLocation();
}, []);

let curDate=new Date();
curDate=curDate.getHours();

let greeting='';
if(curDate<=10){
  greeting='Jam Masuk';
}
else if(curDate<17 ){
  greeting='Jam kerja'; 
}
else if(curDate>=17){
  greeting='Jam Pulang'
}
else 
{
  greeting='Good night'
}


const fetchData = async () => {
  showLoader();
  await axios.get("https://hris.anyargroup.co.id/api/presensi")
  .then((response) => {
    hideLoader();
    setPresensi(response.data.data);
  })
}

const getLocation = () => {
  if (!navigator.geolocation) {
    setStatus('Geolocation is not supported by your browser');
  } else {
    setStatus('Locating...');
    navigator.geolocation.getCurrentPosition((position) => {
      setStatus(null);
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
    }, () => {
      setStatus('Unable to retrieve your location');
    });
  }
}


    return (
      
      <>
    
     
        <div className="attedance">
          
        <div className="title-attedance">
            <h5 className="title-text">Attendance <span className="title-span">Employee</span></h5>
        </div>
        <div className="waktu">
            <h5 className="work-in">{greeting}</h5>
        </div>
        <div className="waktu-attedance">
          <h5 className="work-attedance"><img className="gambar-clock" src={clock_gambar}></img>Clock : {clock}</h5>
        </div>

        <div className="container container-camera">
          <div className="row justify-content-center row-camera">

            <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-camera">
            
              <CameraFeed sendFile={captureImage} className="camera"/>
              <div style={{color:"red", fontSize:"14px", textAlign:"center",fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}> 
                  {error&&img.length<=0?
                  <label >You haven't captured a photo&nbsp;</label>:""}
               </div>
            <div className="row justify-content-center row-input">
            <div className="col-xl-12 col-input">
                <div className="card card-attedance">
                <form enctype="multipart/form-data" onSubmit={handleSubmit}>
                <div className="col"> 
                <label className="label-attedance">Input Your NIK</label>
                <input className="form-control form-control-sm input-nik" 
                 onChange={(e) => {
                  const re = /^[0-9\b]+$/; //rules
                  if (e.target.value === "" || re.test(e.target.value)) {
                    setNik(e.target.value);
                  }}}
                  ></input>
                  <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}> 
                                                {error&&nik.length<=0?
                                                  <label >NIK can't be Empty&nbsp;</label>:""}
                                                  </div>
                </div>
                <div className="col">
                <button className="btn btn-attedance" type="submit">Post</button>
                </div>
                </form>
                </div>
          
            </div>
            </div>
          
            </div>
            <div className="col-xl-4 col-lg-6 col-md-7 col-sm-12 col-result">
              
              <div  className="row row-result-1">
                <div className="marquee">
                <div className="mark">  
            {presensi.map((absen, index) => (
              <div className="col-xl-12 col-lg-9 col-md-12 col-result-1" key={index}>
                <div className="card card-result-1">
                  <div className="row row-card-result-2">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-4 col-card-result-2-1">
                    <img src={`https://hris.anyargroup.co.id/storage/presensi/${absen.image_masuk}`}  className="img-result" ></img>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-8 col-card-result-2-2">
                    <div className="card card-keterangan">
                      <h5 className="title-name">{absen.preskaryawan.nama_lengkap.substring(0, 10)}...</h5>
                      <h5 className="title-level"><img className="gambar-level" src={level}></img>{absen.preskaryawan.jabatan.nama.substring(0, 19)}</h5>
                      <h5 className="title-time"><img className="gambar-time" src={time}></img>Work in : {absen.jam_masuk.substring(11, 19)}</h5>
                      {(() => {
                                    if (absen.keterangan == "Late") {
                                      return (
                                        <div>
                                           <h5 className="title-ket" style={{color:"red"}}><img className="gambar-ket" src={keterangan}></img>{absen.keterangan}</h5>
                                        </div>
                                      );
                                    } else if (absen.keterangan == "OnTime") {
                                      return (
                                        <div>
                                            <h5 className="title-ket" style={{color:"green"}}><img className="gambar-ket" src={keterangan}></img>{absen.keterangan}</h5>
                                        </div>
                                      );
                                    } else if (absen.keterangan == null) {
                                      return (
                                        <div>
                                            <h5 className="title-ket"><img className="gambar-ket" src={keterangan}></img></h5>
                                        </div>
                                      );
                                    } 
                                  })( )}
                     
                     
                    </div>
               
                    </div>
                  </div>
                </div>
              </div>
                ))}
            </div>
                </div>
           
          
            </div>
            
            </div> 
           
            
          </div>
        </div>
       
     
      
       
      </div>
      {loader}
      </>
    );
}


export default Attedance;