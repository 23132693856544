
import '../css/style.scss';
import '../css/changepass/changepass.scss';
import axios from 'axios';
import change from '../img/change.png';
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2"; 
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eye1 = <FontAwesomeIcon icon={faEyeSlash} />;

function Change() {
    const [validation, setValidation] = useState([]);
    const [isLoading, setLoading] =  useState(false);
    const token = localStorage.getItem("token");
    const [isLogin, SetIsLogin] = useState(false);
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPass] = useState("");
    const [password1, setPass1] = useState("");
    const [errors, setError] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);

    const togglePasswordVisiblity1 = () => {
    setPasswordShown1(passwordShown1 ? false : true);
    };

    const togglePasswordVisiblity2 = () => {
        setPasswordShown2(passwordShown2 ? false : true);
        };

    useEffect(() => {
        if (token) {
          SetIsLogin(true);
        } else {
          SetIsLogin(false);
        }
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        axios.get("https://hris.anyargroup.co.id/api/profile")
        .then((response) => {
          setEmail(response.data.data.email)
        });
      }, []);
 
      const createProduct = async (e) => {
        setLoading(true);
        e.preventDefault();
        if(password.length==0||password1.length==0){setError(true)}

      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);
      formData.append("confirm_password", password1);
      
      await axios
            .post('https://hris.anyargroup.co.id/api/changepass', formData)
            // .catch((error) => {
            //     history.push("/changepass");
            //             Swal.fire({
            //                 title: 'Data Successfully Update',
            //                 showConfirmButton: false,
            //                 icon: 'info'
            //             })
            //             setLoading(false);
            // })
            .then((response) => {
                console.log(response.data)
                if(response.data.code === "300"){
                        history.push("/changepass");
                        Swal.fire({
                            title: ' Check Your Input',
                            showConfirmButton: false,
                            icon: 'warning'
                        })
                        setLoading(false);
               
                }if(response.data.code === "200"){
                    history.push("/profile");
                    Swal.fire({
                        title: 'Data Successfully Update',
                        showConfirmButton: false,
                        icon: 'info'
                    })
                    setLoading(false);
           
            }
               
              
             
              })
         
       
        };

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);

    const handleCPassword = (e) => {
        setPass1(e.target.value);
        setIsCPasswordDirty(true);
    }

    useEffect(() => {
        if (isCPasswordDirty) {
            if (password === password1) {
                setShowErrorMessage(false);
            } else {
                setShowErrorMessage(true)
            }
        }
    }, [password1])
      
return (
    
    <div className="change">
          {isLogin ? ( 
             <div className="container" style={{ marginTop: "130px" }}>
             <div className="row justify-content-between row-change">
                 <div className="col-md-5 col-lg-5  col-sm-12 col-xs-12 col-change">
                     <div className="title-change">
                         <h2 className="title-content">Change Your <span className="title-content-1">Password</span></h2>
                     </div>
                         <div className="card card-change">
                             <div className="card-body">
                             { 
                            validation.message && (
                            <div className="alert alert-danger" style={{textAlign: "center"}}>
                                {validation.message}
                            </div>)
                        }
                                 <form onSubmit={createProduct}>
                                     <div className="new-password">
                                         <label className="form-label">New Password</label>
                                             <div className="input-group grup-password">
                                                 <input  
                                                 name="new_password" 
                                                 className="form-password form-control" 
                                                 type={passwordShown1 ? "text" : "password"} 
                                                 placeholder="Input New Password"
                                                 onChange={e=>setPass(e.target.value)}/>
                                                 {!passwordShown1 && <i className="eye1" onClick={togglePasswordVisiblity1}>{eye1}</i>}{" "}
                                                 {passwordShown1 && <i className="eye" onClick={togglePasswordVisiblity1}>{eye}</i>}{" "}
                                             </div>
                                             <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                        {errors&&password.length<=0?
                                        <label>password can't be Empty</label>:""}
                                        </div>
                                             
                                     </div>
                            
                                     <div className="confirm-password mb-3">
                                         <label className="form-label">Confirm Password</label>
                                             <div className="input-group grup-password">
                                                 <input  
                                                 name="confirm_password" 
                                                 className="form-password form-control"
                                                 type={passwordShown2 ? "text" : "password"} 
                                                 placeholder="Input Confirm Password"
                                                 onChange={handleCPassword}/>
                                                 {!passwordShown2 && <i className="eye1" onClick={togglePasswordVisiblity2}>{eye1}</i>}{" "}
                                                 {passwordShown2 && <i className="eye" onClick={togglePasswordVisiblity2}>{eye}</i>}{" "}
                                             </div>
                                             <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                        {errors&&password1.length<=0?
                                        <label>password can't be Empty</label>:""}
                                        </div>
                                        {showErrorMessage && isCPasswordDirty ? <div style={{color:"red", fontSize:"14px", marginTop:"5px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}> Passwords did not match </div> : ''}
                                             
                                     </div>
     
                                     <div className="row row-btn">
                                         <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-btn">
                                             <div className="button-change">
                                                 {!isLoading &&  <button type="submit" className="btn btn-change" ><span className="title-change">Submit</span></button> } 
                                                 {isLoading &&  <button type="submit" className="btn btn-change" disabled><i className="fas fa-spinner fa-spin"></i> <span className="title-change">Submit</span></button> } 
                                             </div>
                                         </div>
                                         <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-btn">
                                             <div className="button-signup">
                                             <Link to="/profile"><button className="btn btn-signup" ><span className="title-signup">Cancel</span></button></Link>
                                             </div>
                                         </div>
                                     </div>
                                 </form>
                             </div>
                         </div>  
                 </div>
     
                 <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-img-change">
                     <div className="img-change">
                         <img class="img-change-content" src={change} alt="Logo"/>
                     </div>
                 </div>
             </div>
         </div>
          ) : (
            <div></div>
          )}
   
    </div>
  );
}

export default Change;

