import "../css/style.scss";
import "../css/datapribadi/datapribadi.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Full from './FullLoad';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { useParams, Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
const plus = <FontAwesomeIcon icon={faPlus} />;
const minus = <FontAwesomeIcon icon={faMinus} />;
const back = <FontAwesomeIcon icon={faArrowLeft} />;

export default function Datapribadi() {
  const token = localStorage.getItem("token");

  const params = useParams();

  const history = useHistory();
  const [isLogin, SetIsLogin] = useState(false);
  const [loader, showLoader, hideLoader] = Full();

  const [datauser, setData] = useState("");

  let decoded = base64_decode(params.encoded);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.get("https://hris.anyargroup.co.id/api/profile").then((response) => {
      const formData = response.data.lamar[0].id;
      axios
        .get("https://hris.anyargroup.co.id/api/berkas/getberkas" + "/" + formData)
        .then((res) => {
          setData(res.data.data)
        });
    });
    if (!token) {
      SetIsLogin(true);
      history.push("/error");
    } else {
      SetIsLogin(true);
    }
  }, []);
  


  const [inputFields, setInputFields] = useState([{
    fk_pelamar: decoded
  }]);

  const handleFormChange = (index, event) => {
   let data = [...inputFields];
   data[index][event.target.id] = event.target.value;
   setInputFields(data);
  }



  const tambah = () => {
   let newfield = ({
    fk_pelamar: decoded
   })
   setInputFields([...inputFields, newfield])
  }

  const hapus = (index) => {
   let data = [...inputFields];
   data.splice(index, 1)
   setInputFields(data)
  }
  
   const submit = async (e) => {
    showLoader();
    e.preventDefault();
    axios({
      method: 'post',
      url: 'https://hris.anyargroup.co.id/api/keluarga/insert',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: inputFields
     
  })
 
      .then((response) => {
        hideLoader()
        Swal.fire({
          title: 'Input Data Success',
          showConfirmButton: false,
          icon: 'success'
      })
      history.push("/profile");
      })
      .catch((err) => {
        hideLoader()
        Swal.fire({
          title: 'Please check your input',
          showConfirmButton: false,
          icon: 'warning'
      })
      });
   }

  return (
    <>
     {isLogin ? (
       <div className="datapribadi">
       <div className="container datapribadi-label">
         <div className="label">
          
           <h4 className="datapribadi-title">
             <span className="datapribadi-garis">Personal Data </span>
           </h4>
           <label className="datapribadi-title-content-1">
             Lengkapi form yang ada di bawah ini, sesuai data pribadi anda
           </label>
           <br></br>
           <label className="datapribadi-title-content-2">
             Complete the form below, according to your personal data
           </label>
           
           <div className="datapribadi-garis-1"></div>
         </div>
         </div>

       <div className="container datapribadi-content">
         <div className="content">
           <div className="row form-row">
             <form encType="multipart/form-data" onSubmit={submit} className="form-datapribadi">

               <div className="col-12 form-col">
                 <h5 class="card-title">Family</h5>
                 <div class="card">
                   <div class="card-body">
                   <div className="btn btn-sm btn-add" onClick={tambah}>{plus}</div>
                   <div className="content-table">
               
                       <table  className="table table-borderless table-content">
                               <thead className="head-table-head">
                                 <tr className="head-table">
                                   <th className="anggota">Family</th>
                                   <th className="name">Name</th>
                                   <th className="gender">Gender</th>
                                   <th className="anak">Child</th>
                                   <th className="tempat">Place of Birth</th>
                                   <th className="tanggal">Date of Birth</th>
                                   <th className="usia">Age</th>
                                   <th className="pendidikan">Last Education</th>
                                   <th className="pekerjaan">Profession</th>
                                   <th className="keterangan">Description</th>
                                 </tr>
                               </thead>
                             {inputFields.map((input, index) => {
                                 return (
                                 <tbody key={index} className="body-table-body">
                                   <tr className="body-table">
                                 <td className="content-anggota">
                                   <select className="form form-control form-control-sm" name="anggota" id="anggota" value={input.anggota} onChange={event => handleFormChange(index, event)}>
                                       <option selected disabled value="">Choose Family</option>
                                      <option value="ayah">Ayah</option>
                                      <option value="ibu">Ibu</option>
                                      <option value="ayah mertua">Ayah Mertua</option>
                                      <option value="ibu mertua">Ibu Mertua</option>
                                      <option value="istri">Istri</option>
                                      <option value="anak">Anak</option>
                                      <option value="kaka">Kakak</option> 
                                      <option value="kaka">Adik</option> 
                                   </select>
                                 </td>
                                 <td className="content-nama">
                                   <input className="form form-control form-control-sm " placeholder="Input name" name="nama"   id="nama"  type="text" value={input.nama}
                                   onChange={(event) =>{
                                      let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                     if (event.target.value === "" || re.test(event.target.value)) {
                                       handleFormChange(index, event);
                                     } }}
                                      />
                                 </td>
                                 <td className="content-gender">
                                   <select className="form form-control form-control-sm" name="jenis_kelamin" id="jenis_kelamin"  value={input.jenis_kelamin} onChange={event => handleFormChange(index, event)}>
                                   <option selected disabled value="">Choose Gender</option>
                                      <option value="laki-laki">L</option>
                                      <option value="perempuan">P</option>
                                   </select>
                                 </td>
                                 <td className="content-anak">
                                   <input className="form form-control form-control-sm" maxLength={1} placeholder="Ke-1" name="urutan_anak" id="urutan_anak"  type="text" value={input.urutan_anak} 
                                   onChange={(event) =>{
                                     let re = /^[0-9\-\b]+$/;
                                    if (event.target.value === "" || re.test(event.target.value)) {
                                      handleFormChange(index, event);
                                    } }}/>
                                 </td>
                                 <td className="content-place">
                                   <input className="form form-control form-control-sm" placeholder="Input place" name="tempat_lahir" id="tempat_lahir" type="text" value={input.tempat_lahir} 
                                    onChange={(event) =>{
                                     let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                    if (event.target.value === "" || re.test(event.target.value)) {
                                      handleFormChange(index, event);
                                    } }}/>
                                 </td>
                                 <td className="content-tanggal">
                                   <input className="form form-control form-control-sm" name="tanggal_lahir" id="tanggal_lahir" type="date" value={input.tanggal_lahir} onChange={event => handleFormChange(index, event)}/>
                                 </td>
                                 <td className="content-usia">
                                   <input  className="form form-control form-control-sm" maxLength={2} name="usia" id="usia" type="text" value={input.usia} 
                                   onChange={(event) =>{
                                     let re = /^[0-9\-\b]+$/;
                                    if (event.target.value === "" || re.test(event.target.value)) {
                                      handleFormChange(index, event);
                                    } }}/>
                                 </td>
                                 <td className="content-pendidikan">
                                   <select className="form form-control form-control-sm" name="pendidikan" id="pendidikan" value={input.pendidikan} onChange={event => handleFormChange(index, event)}>
                                   <option selected disabled value="">Choose Education</option>
                                      <option value="belum sekolah">Belum Sekolah</option>
                                      <option value="tk">TK</option>
                                      <option value="sd">SD</option>
                                      <option value="smp">SMP</option>
                                      <option value="sma/smk">SMA / SMK</option>
                                      <option value="s1">S1</option>
                                      <option value="s2">S2</option>
                                      <option value="s3">S3</option>
                                   </select>
                                 </td>
                                 <td className="content-pekerjaan">
                                   <input className="form form-control form-control-sm" placeholder="Input profession" 
                                   name="pekerjaan"
                                   id="pekerjaan"
                                    type="text" value={input.pekerjaan} 
                                   onChange={(event) =>{
                                     let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                    if (event.target.value === "" || re.test(event.target.value)) {
                                      handleFormChange(index, event);
                                    } }}/>
                                 </td>

                                 <td className="content-keterangan">
                                   <input className="form form-control form-control-sm" 
                                   placeholder="Input description" 
                                   name="keterangan" 
                                   id="keterangan" 
                                   type="text" value={input.keterangan} 
                                   onChange={(event) =>{
                                     let re = /^[A-Z\a-z\0-9\ \-\b]+$/;
                                    if (event.target.value === "" || re.test(event.target.value)) {
                                      handleFormChange(index, event);
                                    } }}/>
                                 </td>
                                 
                      
                                 <td>
                                 <button className="btn btn-sm btn-hapus" disabled={index == 0} onClick={() => hapus(index)}><span className="icon">{minus}</span></button>
                                 </td>
                                 
                                
                                   </tr>
                                 </tbody>
                                );
                               })}
                       </table>
                       <span className="note">Note</span><br></br>
                   <span className="note">1. Untuk kolom child diisi anak ke berapa contoh (1/2/3) ketika memilih <b>anak</b> pada kolom family.</span><br></br>
                   <span className="note">2. Apabila telah pensiun/meninggal, isilah Profession saat masih aktif.</span><br></br>
                   <span className="note">3. Isi <b>(-)</b> untuk kolom yang tidak perlu.</span>
                   </div>
                   


                   </div>
                 </div>
               </div>
   

               <div className="col-12 form-button">
                 <center>
                   <button type="submit" className="button-inputdata">
                     <a className="inputdata">Submit</a>
                   </button>
                 </center>
               </div>
             </form>
             
            
           </div>
         </div>

       </div>
       {loader}
     </div>
     ):(
      <h5>data readyu</h5>
     )}
     
    </>
  );
}
