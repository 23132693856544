import '../css/style.scss';
import '../css/footer/footer.scss';
import React from "react";
import { Link } from 'react-router-dom';

function Footer() {
return (
   <div class="footer">
       <div className="footer-content">
           <div className="container">
           <div className="row justify-content-center row-footer">
               <div className="col-lg-2 col-md-3 col-sm-3 col-xs-6 col-footer-1">
                   <h5 className="title-footer1">Information</h5>
                   <ul className="list1">
                        <li><Link className="list-content" to="/">Home</Link></li>
                       <li><Link className="list-content" to="/vacancy">Career</Link></li>
                       {/* <li><Link className="list-content" to="/">Lorem</Link></li>
                       <li><Link className="list-content" to="/">Lorem</Link></li> */}
                   </ul>
               </div>
               <div className="col-lg-2 col-md-3 col-sm-3 col-xs-6 col-footer-2">
               <h5 className="title-footer2">About</h5>
                   <ul className="list2">
                        <li><a className="list-content" href="https://anyargroup.co.id/" target="_blank">PT.Anyar Retail Indonesia</a></li>
                        {/* <li><Link className="list-content" to="/">Lorem</Link></li>
                        <li><Link className="list-content" to="/">Lorem</Link></li>
                        <li><Link className="list-content" to="/">Lorem</Link></li> */}
                   </ul>
               </div>
               <div className="col-lg-2 col-md-3 col-sm-3 col-xs-6 col-footer-3">
               <h5 className="title-footer3">Branch</h5>
                   <ul className="list3">
                        <li><a className="list-content" href="https://rkm.co.id/" target="_blank">RKM</a></li>
                        <li><a className="list-content" href="#">Triwarna</a></li>
                        <li><a className="list-content" href="#" target="_blank">Lantai & Dinding</a></li>
                        {/* <li><Link className="list-content" to="/login">Lorem</Link></li> */}
                   </ul>
               </div>
               <div className="col-lg-6 col-footer-4">
               <iframe className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126744.9221081116!2d107.57209519700935!3d-6.917026462022396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e732ede5fd55%3A0x373dc464c061e782!2sPT.%20Anyar%20Retail%20Indonesia!5e0!3m2!1sid!2sid!4v1654580650037!5m2!1sid!2sid" width="relative" height="relative" style={{border:0, float:'right',}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               </div>
           </div>
           </div>
       </div>
       <div className="footer-last">
            <span className="title-last">@DEV IT - Anyargroup</span>
        </div>
   </div> 
  );
}

export default Footer;

