import "../css/style.scss";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
const arrow = <FontAwesomeIcon icon={faArrowUp} />;


const ScrollBtn = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    
    return (
        <div className="top-to-btm">
            {" "}
            {showTopBtn && (
                 <div className="icon-position icon-style"  onClick={goToTop}>
                    <span className="ikon" >{arrow}</span>
             
                    </div>
              
            )}{" "}
        </div>
    );
};
export default ScrollBtn;
