import "../css/style.scss";
import "../css/inputdata/inputdata.scss";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert2";  
import Full from './FullLoad';


export default function InputData() {
  const history = useHistory();
  const token = localStorage.getItem("token");
  const [isLoading, setLoading] =  useState(false);
  const [loader, showLoader, hideLoader] = Full();
  const [isLoaded, SetIsLoaded] = useState(false);
  const [error,setError]=useState(false)

  const [nama_lengkap, setName] = useState("");
  const [nik, setNik] = useState("");
  const [gender, setGender] = useState("");
  const [tgl_lahir, setTgllahir] = useState("");
  const [tempat_lahir, setTempatlahir] = useState("");
  const [status_pernikahan, setStatus] = useState("");
  const [agama, setAgama] = useState("");
  const [photo, setPhoto] = useState("");
  const [cv, setCv] = useState("");
  const [alamat, setAlamat] = useState("");
  const [rt, setRt] = useState("");
  const [rw, setRw] = useState("");
  const [desa, setDesa] = useState("");
  const [kecamatan, setKecamatan] = useState("");
  const [kota, setKota] = useState("");
  const [kodepos, setKodepos] = useState("");
  const [provinsi, setProvinsi] = useState("");
  const [status_rumah, setStatusrumah] = useState("");
  const [no_telp, setNotelp] = useState("");
  const [no_hp, setNohp] = useState("");
  const [pendidikan_terakhir, setPendidikanterakhir] = useState("");
  const [tahun_masuk, setTahunmasuk] = useState("");
  const [nama_sekolah, setNamasekolah] = useState("");
  const [tahun_lulus, setTahunlulus] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [ipk, setIpk] = useState("");
  const [fk_user, setfkUser] = useState("");
  const [lamar, setLamar] = useState("");
  const [photoname, setPhotoname] = useState("");
  const [cvname, setCvname] = useState("");
  const [hub, setHubungan] = useState("");
  const [refjob, setRefjob] = useState("");
  const [sosmed, setSosmed] = useState("");


  const [errorMsg, setErrorMsg] = useState(false);
  const [errorMsg1, setErrorMsg1] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccess1, setIsSuccess1] = useState(false);


  const changeHandler = (event) => {
    if(event.target.files.length > 0){
      setPhoto(event.target.files[0]);
      setPhotoname(event.target.files[0].name);
    }
  };

  const changeHandlerpdf = (event) => {
    if(event.target.files.length > 0){
      setCv(event.target.files[0]);
      setCvname(event.target.files[0].name);
     
    }
  };

  const fetchData = async () => {
    showLoader();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios.get("https://hris.anyargroup.co.id/api/profile").then((response) => {
      setfkUser(response.data.data);
      setNohp(response.data.data);
      // setLamar(response.data.lamar);
      console.log("ini data profile = ",response.data.data )
      hideLoader();
    });
  };


  useEffect(() => {
    if (!token) {
      SetIsLoaded(true);
      history.push("/error");
    } else {
      SetIsLoaded(true);
    }
    fetchData();
  }, []);

  const example = () =>{
    Swal.fire({
      title: "List Documents (Dalam 1 File)",
      html:
    '<p><h6>1. Scan Curriculum Vitae (CV) <span style="color:red;">*</span></h6></p>' + 
    '<p><h6>2. Scan Surat Lamaran Kerja <span style="color:red;">*</span></h6></p>'+
    '<p><h6>3. Scan Pas Foto (4x6 Berwarna) <span style="color:red;">*</span></h6></p>'+
    '<p><h6>4. Scan KTP <span style="color:red;">*</span></h6></p>'+
    '<p><h6>5. Scan Surat Keterangan Sehat <span style="color:red;">*</span></h6></p>'+
    '<p><h6>6. Scan Sertifikat Vaksin (min. Dosis 2) <span style="color:red;">*</span></h6></p>'+
    '<p><h6>7. Scan Kartu Keluarga <span style="color:red;">*</span></h6></p>'+
    '<p><h6>8. Scan NPWP (Jika ada)</h6></p>'+
    '<p><h6>9. Scan Ijazah/Surat Tanda Lulus <span style="color:red;">*</span></h6></p>'+
    '<p><h6>10. Scan Transkrip Nilai / SKHUN <span style="color:red;">*</span></h6></p>' +
    '<p><h6>11. Scan Surat Paklaring (Jika ada) <span style="color:red;">*</span></h6></p>' +
    '<p><h6>12. Scan SIM A/B1/C (Khusus Driver/supir) <span style="color:red;">*</span></h6></p>'+
    '<p><h6>13. Scan Surat Izin Operator / SIO</h6></p>'+
    '<p><h6>14. Scan Sertifikat Pendukung</h6></p>',
      showConfirmButton: true,
     
  })
  }
  //**on submit button pelamar */
  const createProduct = async (e) => {
    showLoader();
    e.preventDefault();
    if(nama_lengkap.length==0||nik.length==0||gender.length==0||tgl_lahir.length==0||tempat_lahir.length==0
      ||status_pernikahan.length==0||agama.length==0||cv.length==0||photo.length==0||alamat.length==0||rt.length==0
      ||rw.length==0||desa.length==0||kecamatan.length==0||kota.length==0||kodepos.length==0||provinsi.length==0
      ||status_rumah.length==0||no_telp.length==0||no_hp.length==0||pendidikan_terakhir.length==0||tahun_masuk.length==0||nik.length==0
      ||nama_sekolah.length==0||tahun_lulus.length==0||jurusan.length==0||ipk.length==0||hub.length==0||sosmed.length==0||refjob.length==0){
      setError(true)
  }

  const MAX_FILE_SIZE = 2048 // 5MB
  const MAX_FILE_SIZEPDF = 5000 // 5MB


  const fileSizeKiloBytes = photo.size / 1000
  const fileSizeKiloBytes1 = cv.size / 2548


  if(fileSizeKiloBytes > MAX_FILE_SIZE){
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    hideLoader();
    setErrorMsg("File size maximum 2Mb");
    setIsSuccess(false);
    return

  }

   if(fileSizeKiloBytes1 > MAX_FILE_SIZEPDF){
    Swal.fire({
      title: 'Please check your input',
      showConfirmButton: false,
      icon: 'warning'
  })
    hideLoader();
    setErrorMsg1("File size maximum 5Mb");
    setIsSuccess1(false)
    return
  }

    const formData = new FormData();
    formData.append("nama_lengkap", nama_lengkap);
    formData.append("nik", nik);
    formData.append("no_hp", no_hp.phone_number);
    formData.append("no_telp", no_telp);
    formData.append("tgl_lahir", tgl_lahir);
    formData.append("agama", agama);
    formData.append("photo", photo);
    formData.append("gender", gender);
    formData.append("tempat_lahir", tempat_lahir);
    formData.append("status_pernikahan", status_pernikahan);
    formData.append("cv", cv);
    formData.append("alamat", alamat);
    formData.append("rt", rt);
    formData.append("rw", rw);
    formData.append("desa", desa);
    formData.append("kecamatan", kecamatan);
    formData.append("kota", kota);
    formData.append("provinsi", provinsi);
    formData.append("kodepos", kodepos);
    formData.append("status_rumah", status_rumah);
    formData.append("pendidikan_terakhir", pendidikan_terakhir);
    formData.append("nama_sekolah", nama_sekolah);
    formData.append("jurusan", jurusan);
    formData.append("tahun_masuk", tahun_masuk);
    formData.append("tahun_lulus", tahun_lulus);
    formData.append("ipk", ipk);
    formData.append("hubungan_keluarga", hub);
    formData.append("sosial_media", sosmed);
    formData.append("referensi_job", refjob);
    formData.append("fk_user", fk_user.id);

    await axios
    
      .post("https://hris.anyargroup.co.id/api/pelamar", formData)
      .then((response) => {
        Swal.fire({
          title: 'Data input successfully',
          showConfirmButton: false,
          icon: 'info'
      })
      hideLoader();
        history.push("/vacancy");
      })
      .catch((err) => {
        if(err.response.data.code === "reg1"){
          Swal.fire({
            title: "Your NIK has been already",
            showConfirmButton: false,
            icon: "warning",
          })
          hideLoader();
      } 
        hideLoader();
        history.push("/regis");
      });
    setErrorMsg("")
    setErrorMsg1("")
    setIsSuccess(true)
    setIsSuccess1(true)
  };

  return (
    <>
    <div className="inputdata">
              {(() => {         
                       if (lamar == 0) {
                        return (
                          <div>
                             {isLoaded ? (
                                <div className="container inputdata-label">
                                <div className="label">
                                  <h4 className="inputdata-title">
                                    <span className="inputdata-garis">Complete Data</span>
                                  </h4>
                                  <label className="inputdata-title-content-1">
                                    Mohon mengisi bagian yang ditandai (*) dengan lengkap
                                  </label>  
                                  <br></br>
                                  <label className="inputdata-title-content-2">
                                    Please input your information for required field (*)
                                  </label>
                                  <div className="inputdata-garis-1"></div>
                                </div>
                                <div className="content">
                                  <div className="row form-row">
                                    <form encType="multipart/form-data" onSubmit={createProduct}>
                                      <div className="col-12 form-col">
                                        <h5 class="card-title">Data Pribadi</h5>
                                        <div class="card">
                                          <div class="card-body">
                                            <div className="mb-2 row">
                                            <label
                                                for="Nama"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Nama Lengkap<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="nama"
                                                  id="nama"
                                                  value={nama_lengkap}
                                                  placeholder="Masukkan Nama Lengkap"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\ \b]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setName(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&nama_lengkap.length<=0?<label>Nama Lengkap tidak boleh kosong</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>
                                            <div class="mb-2 row">
                                              <label
                                                for="NoKtp"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Nomor Identitas KTP<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  maxLength={16}
                                                  name="nik"
                                                  id="Noktp"
                                                  value={nik}
                                                  placeholder="Masukkan NIK"
                                                  className="form-control form-control-sm input"
                                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setNik(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}> 
                                                {error&&nik.length<=0?
                                                  <label >NIK Tidak boleh kosong dan harus berbentuk angka&nbsp;</label>:""}
                                                  </div>
                                              </div>

                                              <label
                                                for="Gender"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Jenis Kelamin<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <select
                                                  class="form-select form-select-sm input"
                                                  type="text"
                                                  name="gender"
                                                  aria-label=".form-select select example"
                                                  onChange={e=>setGender(e.target.value)}
                                                >
                                                  <option selected disabled value="">
                                                    Pilih Jenis Kelamin
                                                  </option>
                                                  <option value="Laki-laki">Laki-laki</option>
                                                  <option value="Perempuan">Perempuan</option>
                                                </select>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&gender.length<=0?
                                                  <label>Jenis Kelamin Wajib Diisi!</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>

                                          
                                            <div class="mb-2 row">
                                              <label
                                                for="DateBirth"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                                                Tanggal Lahir<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="date"
                                                  name="tgl_lahir"
                                                  id="TanggalLahir"
                                                  min="01-01-2005" max="2005-12-31"
                                                  className="form-control form-control-sm input"
                                                  onChange={e =>setTgllahir(e.target.value)}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&tgl_lahir.length<=0?
                                                  <label>Date Of Birth can't be Empty</label>:""}
                                                </div>
                                              
                                              </div>

                                              <label for="CityBirth"className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label">
                                                Tempat Lahir<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="tempat_lahir"
                                                  value={tempat_lahir}
                                                  id="City_birth"
                                                  placeholder="Masukkan Tempat Lahir"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\ \b]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setTempatlahir(e.target.value);
                                                    }}}
                                                ></input>
                                                  <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                  {error&&tempat_lahir.length<=0?
                                                  <label>Tempat lahir tidak boleh kosong</label>:""}
                                                  </div>
                                                
                                              </div>
                                            </div>
                                            <div class="mb-2 row">
                                            
                                              <label
                                                for="Martial"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Status Pernikahan<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <select
                                                  class="form-select form-select-sm input"
                                                  type="text"
                                                  name="status_pernikahan"
                                                  aria-label="form-select select example"
                                                  onChange={e => setStatus(e.target.value)}
                                                >
                                                  <option selected disabled value="">
                                                    Pilih Status Pernikahan
                                                  </option>
                                                  <option value="Single">Belum Menikah</option>
                                                  <option value="Married">Menikah</option>
                                                  <option value="Divorced">Cerai</option>
                                                </select>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&status_pernikahan.length<=0?
                                                  <label>Status pernikahan tidak boleh kosong</label>:""}
                                                </div>
                                              
                                              </div>

                                              <label
                                                for="Religion"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              > 
                                                Agama<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <select
                                                  class="form-select form-select-sm input"
                                                  type="text"
                                                  name="agama"
                                                  aria-label=".form-select select example"
                                                  onChange={e=>setAgama(e.target.value)}
                                                >
                                                  <option selected disabled value="">
                                                    Pilih Agama
                                                  </option>
                                                  <option value="Islam">Islam</option>
                                                  <option value="Katolik">Katolik</option>
                                                  <option value="Protestan">Protestan</option>
                                                  <option value="Buddha">Buddha</option>
                                                  <option value="Hindu">Hindu</option>
                                                  <option value="Khonghucu">Khonghucu</option>
                                                </select>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&agama.length<=0?
                                                  <label>Agama tidak boleh kosong</label>:""}
                                                </div>
                                              
                                              </div>

                                          
                                            </div>

                                            <div class="mb-2 row">
                                              <label
                                                for="DateBirth"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Pas Foto
                                                <span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                              
                                                <div className="input-group">
                                                <input
                                                  aria-label="file example"
                                                  type="file"
                                                  name="photo_profile"
                                                  aria-required
                                                  className="form-control form-control-sm input"
                                                  accept="image/*"
                                                  data-classButton="btn btn-primary"
                                                  onChange={changeHandler}
                                                  style={{display:"none"}}
                                                  id="inputGroupFile02"
                                                />
                                                <input placeholder="Pilih Foto" style={{borderTopLeftRadius:"5px",borderBottomLeftRadius:"5px",borderRight:"none"}}  className="form-control form-control-sm input" value={photoname}></input>
                                                  <label htmlFor="inputGroupFile02">
                              <div className="btn btn-sm" style={{backgroundColor:"#044F9F",color:"#fff",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px",
                            borderBottomRightRadius:"5px",borderTopRightRadius:"5px",}}>
                                Browse
                              </div>
                                                  </label>
                                                </div>
                                            
                                                  <span style={{fontSize: "11px", color: "green"}}>Max 2Mb (Jpeg or Jpg)</span>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&photo.length<=0?
                                                  <label>Photo Profile can't be Empty</label>:""}
                                                    
                                              
                                                </div>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                  <p className="error-message">{errorMsg}</p>
                                              
                                                </div>
                                              
                                                
                                              </div>

                                              <label
                                                for="CityBirth"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Dokumen
                                                <span className="wajib">*</span>
                                                <br></br>
                                                <span style={{fontSize: "13px", color: "#f50057", fontWeight:"700"}}  onClick={example} >Contoh Dokumen(Klik Disini)</span>
                                              </label>

                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                              <div className="input-group">
                                                <input
                                                    aria-label="file example"
                                                    type="file"
                                                    name="cv"
                                                    accept="application/pdf,application/vnd.ms-excel"
                                                    className="form-control form-control-sm input"
                                                    data-classButton="btn btn-primary"
                                                    onChange={changeHandlerpdf}
                                                    style={{display:"none"}}
                                                    id="inputGroupFile"
                                                />
                                                <input placeholder="Pilih file PDF" style={{borderTopLeftRadius:"5px",borderBottomLeftRadius:"5px",borderRight:"none"}}  className="form-control form-control-sm input" value={cvname}></input>
                                                <label htmlFor="inputGroupFile">
                                                    <div className="btn btn-sm" style={{backgroundColor:"#044F9F",color:"#fff",borderTopLeftRadius:"0px",borderBottomLeftRadius:"0px",
                                                  borderBottomRightRadius:"5px",borderTopRightRadius:"5px",}}>
                                                      Browse
                                                    </div>
                                                </label>
                                              </div>
                                              
                                                <span style={{fontSize: "11px", color: "green"}}>Max 5Mb (Pdf)</span>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&cv.length<=0?
                                                  <label>Documents can't be Empty</label>:""}
                                                            
                                                </div>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                    <p className="error-message">{errorMsg1}</p>
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 form-col">
                                        <h5 class="card-title">Alamat</h5>
                                        <div class="card">
                                          <div class="card-body">
                                            <div class="mb-3 row row-input">
                                              <label
                                                for="Address"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Alamat Domisili<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-10 col-lg-9 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="alamat"
                                                  id="Address"
                                                  value={alamat}
                                                  placeholder="Masukkan Alamat Domisili"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\0-9\ \.\,\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setAlamat(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&alamat.length<=0?
                                                  <label>Alamat tidak boleh kosong</label>:""}
                                                </div>
                                              
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="Rt"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                RT<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  maxLength={3}
                                                  name="rt"
                                                  id="Rt"
                                                  value={rt}
                                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                                  placeholder="Masukkan RT"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setRt(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&rt.length<=0?
                                                  <label>RT tidak boleh kosong dan harus berbentuk angka</label>:""}
                                                </div>
                                                
                                              </div>

                                              <label
                                                for="Rw"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                RW<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  maxLength={3}
                                                  name="rw"
                                                  id="Rw"
                                                  value={rw}
                                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                                  placeholder="Masukkan RW"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setRw(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&rw.length<=0?
                                                  <label>RW tidak boleh kosong dan harus berbentuk angka</label>:""}
                                                </div>
                                              
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="Village"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Kelurahan / Desa<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="desa"
                                                  id="Village"
                                                  value={desa}
                                                  placeholder="Masukkan Kelurahan / Desa"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\0-9\ \b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setDesa(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&desa.length<=0?
                                                  <label>Kelurahan / Desa can't be Empty</label>:""}
                                                </div>
                                                
                                              </div>

                                              <label
                                                for="District"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Kecamatan<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="kecamatan"
                                                  id="District"
                                                  value={kecamatan}
                                                  placeholder="Masukkan Kecamatan"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\0-9\ \b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setKecamatan(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&kecamatan.length<=0?
                                                  <label>District can't be Empty</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="City"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Kota<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="kota"
                                                  id="City"
                                                  value={kota}
                                                  placeholder="Masukkan Nama Kota"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\ \b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setKota(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&kota.length<=0?
                                                  <label>Kota tidak boleh kosong</label>:""}
                                                </div>
                                              
                                              </div>

                                              <label
                                                for="PostalCode"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Kode Pos<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  maxLength={6}
                                                  name="kode_pos"
                                                  id="PostalCode"
                                                  value={kodepos}
                                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                                  placeholder="Masukkan Kode Pos"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setKodepos(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&kodepos.length<=0?
                                                  <label>Kode pos tidak boleh kosong dan harus berbentuk angka</label>:""}
                                                </div>
                                              
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="Province"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Provinsi<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="provinsi"
                                                  id="Province"
                                                  value={provinsi}
                                                  placeholder="Masukkan Provinsi"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\ \b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setProvinsi(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&provinsi.length<=0?
                                                  <label>Provinsi tidak boleh kosong</label>:""}
                                                </div>
                                                
                                              </div>

                                              <label
                                                for="ResidentialStatus"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Status Tinggal<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <select
                                                  class="form-select form-select-sm input"
                                                  type="text"
                                                  name="status_rumah"
                                                  aria-label="form-select select example"
                                                  onChange={e=>setStatusrumah(e.target.value)}
                                                >
                                                  <option selected disabled value="">
                                                    Pilih Status Tinggal
                                                  </option>
                                                  <option value="Rumah Pribadi">Rumah Pribadi</option>
                                                  <option value="Rumah Orang Tua">
                                                    Rumah Orang Tua
                                                  </option>
                                                  <option value="Rumah Keluarga">
                                                    Rumah Keluarga
                                                  </option>
                                                  <option value="Kontrak">Kontrak</option>
                                                  <option value="Kost">Kost</option>
                                                </select>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&status_rumah.length<=0?
                                                  <label>Status Tinggal tidak boleh kosong</label>:""}
                                                </div>
                                            
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="Phone"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Nomor Telepon<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  maxLength={13}
                                                  name="no_telp"
                                                  id="Phone"
                                                  value={no_telp}
                                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                                  placeholder="Masukkan Nomor Telepon"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setNotelp(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&no_telp.length<=0?
                                                  <label>Nomor telepon tidak boleh kosong dan harus berbentuk angka</label>:""}
                                                </div>
                                                
                                              </div>

                                              <label
                                                for="Phone"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Nomor HP<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  name="no_hp"
                                                  id="Phone"
                                                  readOnly
                                                  placeholder={fk_user.phone_number}
                                                  className="form-control form-control-sm input"
                                                ></input>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 form-col">
                                        <h5 class="card-title">Pendidikan</h5>
                                        <div class="card">
                                          <div class="card-body">
                                            <div class="mb-3 row">
                                              <label
                                                for="LastEducation"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Pendidikan Terakhir<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <select
                                                  class="form-select form-select-sm input"
                                                  type="text"
                                                  name="pendidikan_terakhir"
                                                  aria-label=".form-select select example"
                                                  onChange={e=>setPendidikanterakhir(e.target.value)}
                                                >
                                                  <option selected disabled value="">
                                                    Pilih Pendidikan Terakhir
                                                  </option>
                                                  <option value="SD">SD</option>
                                                  <option value="SMP">SMP</option>
                                                  <option value="SMA">SMA</option>
                                                  <option value="SMK">SMK</option>
                                                  <option value="Diploma 1">Diploma 1</option>
                                                  <option value="Diploma 2">Diploma 2</option>
                                                  <option value="Diploma 3">Diploma 3</option>
                                                  <option value="Diploma 4">Diploma 4</option>
                                                  <option value="Strata 1">Strata 1</option>
                                                  <option value="Strata 2">Strata 2</option>
                                                  <option value="Strata 3">Strata 3</option>
                                                </select>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&pendidikan_terakhir.length<=0?
                                                  <label>Pendidikan Terakhir tidak boleh kosong</label>:""}
                                                </div>
                                              
                                              </div>

                                              <label
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Tahun Masuk Pendidikan<span className="wajib">*</span>
                                              </label>

                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="date"
                                                  name="tahun_masuk"
                                                  id="From"
                                                  className="form-control form-control-sm input"
                                                  onChange={e=>setTahunmasuk(e.target.value)}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&tahun_masuk.length<=0?
                                                  <label>Start college can't be Empty</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="Country"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Nama Sekolah / Universitas<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="nama_sekolah"
                                                  id="School"
                                                  value={nama_sekolah}
                                                  placeholder="Masukkan nama sekolah / universitas"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\0-9\ \.\,\b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setNamasekolah(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&nama_sekolah.length<=0?
                                                  <label>Nama sekolah tidak boleh kosong</label>:""}
                                                </div>
                                              
                                              </div>

                                              <label
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Tanggal Lulus Pendidikan<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="date"
                                                  name="tahun_lulus"
                                                  id="Until"
                                                  className="form-control form-control-sm input"
                                                  onChange={e=>setTahunlulus(e.target.value)}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&tahun_lulus.length<=0?
                                                  <label>Tahun lulus tidak boleh kosong</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>

                                            <div class="mb-3 row">
                                              <label
                                                for="City"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Jurusan<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="jurusan"
                                                  id="Major"
                                                  value={jurusan}
                                                  placeholder="Masukkan Jurusan"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\ \b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setJurusan(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&jurusan.length<=0?
                                                  <label>Jurusan tidak boleh kosong</label>:""}
                                                </div>
                                                
                                              </div>

                                              <label
                                                for="Gpa"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                IPK / Nilai Akhir<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="ipk"
                                                  id="Gpa"
                                                  value={ipk}
                                                  maxLength={4}
                                                  pattern="^[0-9]*[.,]?[0-9]*$"
                                                  placeholder="Masukkan IPK / Nilai Akhir"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\.\,]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setIpk(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&ipk.length<=0?
                                                  <label>Nilai Akhir tidak boleh kosong dan harus berbentuk angka</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 form-col">
                                        <h5 class="card-title">Referensi<span style={{color:"red", fontSize:"18px"}}> *</span></h5>
                                        <div class="card">
                                          <div class="card-body">
                                            <div class="mb-3 row" name="hubungan_keluarga"  onChange={e=>setHubungan(e.target.value)}>
                                              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6">
                                                <input type="radio" value="Teman" checked={hub === "Teman"}/> Teman 
                                              </div>
                                              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6">
                                                <input type="radio" value="Saudara Dekat"  checked={hub === "Saudara Dekat"}/> Saudara Dekat
                                              </div>
                                              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6">
                                                <input type="radio" value="Saudara Jauh"   checked={hub === "Saudara Jauh"}/> Saudara Jauh
                                              </div>
                                              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-6">
                                                <input type="radio" value="Tidak Ada"  checked={hub === "Tidak Ada"}/> Tidak ada
                                              </div>
                                            </div>
                                            
                                            <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&hub.length<=0?
                                                  <label>Referensi tidak boleh kosong</label>:""}
                                          </div>
                                          </div>
                                        
                                        </div>
                                      </div>

                                      <div className="col-12 form-col">
                                        <h5 class="card-title">Info Lowongan Kerja / Sosial Media</h5>
                                        <div class="card">
                                          <div class="card-body">
                                          
                                            <div class="mb-3 row">
                                              <label
                                                for="Job"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Darimana Mendapatkan Info Lowongan Kerja Ini?<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="refjob"
                                                  id="refjob"
                                                  value={refjob}
                                                  placeholder="(Jobstreet/LinkedIn/Lainnya)"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[A-Z\a-z\ \b]+$/; //rules
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setRefjob(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&refjob.length<=0?
                                                  <label>Job Reference can't be Empty</label>:""}
                                                </div>
                                                
                                              </div>

                                              <label
                                                for="Sosmed"
                                                className="col-xl-2 col-lg-3 col-md-4 col-sm-5 col-xs-2 col-form-label"
                                              >
                                                Sosial Media<span className="wajib">*</span>
                                              </label>
                                              <div className="col-xl-4 col-lg-3 col-md-8 col-sm-7 col-xs-10 col-form-input">
                                                <input
                                                  type="text"
                                                  name="sosmed"
                                                  id="sosmed"
                                                  value={sosmed}
                                                  placeholder="(Instagram/Facebook)"
                                                  className="form-control form-control-sm input"
                                                  onChange={(e) => {
                                                    const re = /^[0-9\a-z\A-Z\ \@\-\_\~\.\,]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setSosmed(e.target.value);
                                                    }}}
                                                ></input>
                                                <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                                {error&&sosmed.length<=0?
                                                  <label>Sosial Media can't be Empty</label>:""}
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 form-noted">
                                        <div className="card">
                                          <div className="card-body">
                                            <span className="span-1">
                                              Pastikan seluruh data registrasi yang di isi sudah
                                              benar.{" "}
                                              <span className="span-bold">
                                                Data yang sudah diinput tidak dapat diubah
                                              </span>
                                            </span>
                                            <br></br>
                                            <span className="span-2">
                                              <i>
                                                Make sure all registration data that is filled in is
                                                correct.{" "}
                                                <span className="span-bold">
                                                  The data that has been input cannot be changed
                                                </span>
                                              </i>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 form-button">
                                        <center>
                                        {!isLoading &&  <button type="submit" className="button-inputdata" ><span className="inputdata">Submit</span></button> } 
                                        {isLoading &&  <button type="submit" className="button-inputdata" disabled><i className="fas fa-spinner fa-spin"></i> <span className="inputdata"> Submit</span></button> } 
                                          {/* <button type="submit" className="button-inputdata">
                                            
                                            <a className="inputdata">Submit</a>
                                          </button> */}
                                        </center>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                                ) : (
                                <div className="col-12 form-noted"></div>
                              )}
                          </div>
                        );
                      } else if (lamar == lamar) {
                        return (
                        <h5 style={{marginTop:"50px", textAlign:"center"}}>Your Data Has Been Add</h5>
                        );
                      } 
                    })()}
      
    </div>
    {loader}
    </>
  );
}
