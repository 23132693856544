import '../css/style.scss';
import '../css/navbar/navbar.scss';
import logo from '../img/anyargroup.png';
import { Link, useLocation  } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import Full from './FullLoad';
import Swal from "sweetalert2";



function Navbar() {

  const location = useLocation(); 
  const [url, setUrl] = useState(null);
  const [value, setValue] = useState(null);
  const [isLogin, SetIsLogin] = useState(false);
  const [loader, showLoader, hideLoader] = Full();
  const history = useHistory();
  const token = localStorage.getItem('token');

  const fireAlert3 = () => {
    Swal.fire({
      icon: "warning",
      title: "Your data is not complete",
      showConfirmButton: false,
      footer: '<a style="text-decoration:none; color:#1572a1; font-weight:500;" href="/regis">Please complete data vacancy</a>',
    });
  };

  const logout = async () => {
    showLoader();
       axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
       await axios.post('https://hris.anyargroup.co.id/api/logout')
       .then(() => {
          history.push('/');
           localStorage.removeItem('token');
           SetIsLogin(false)
           hideLoader()
           window.location.reload()
       });
  };

  const fetchData = async () => { 
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios.get("https://hris.anyargroup.co.id/api/profile")
    .then((response) => {
      setValue(response.data.lamar);
    });
  };

  useEffect(() => {
    if (token) {
      SetIsLogin(true);
    } else {
      SetIsLogin(false);
    }
    setUrl(location.pathname);
    fetchData();
  }, [location]);

  return (
    <div className="navbar-atas">

     {isLogin ? (
       <nav className="navbar navbar-expand-lg navbar-light fixed-top">
         <div className="container-fluid container">
           <a className="navbar-brand"><img src={logo} alt="Logo"/></a>
             <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
             </button>
               <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                 <div className="navbar-nav">
                    <a className="nav-link">
                    <Link 
                      to="/"
                      className={"nav-link" + 
                      (url === "/" ?" active" : "") + 
                      (url === "/regis" ?" active" : "")}>
                      Dashboard
                    </Link>
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/vacancy"
                      className={"nav-link" + 
                      (url === "/vacancy" ?" active" : "") + 
                      (url === "/detail/:id" ?" active" : "")}>
                          Vacancy
                    </Link>    
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/liststatus"
                      className={"nav-link" + 
                      (url === "/status" ?" active" : "")+
                      (url === "/liststatus" ?" active" : "")}>
                        Status
                    </Link>
                    </a>

              
                   {(() => {
                      if (value == 0)  {
                        return (
                           <a className="nav-link" onClick={fireAlert3}>
                           <Link 
                               
                               className={"nav-link" + 
                               (url === "/profile" ?" active" : "") + 
                               (url === "/edit" ?" active" : "")}>
                                 Profile
                           </Link>
                         </a>
                              );
                          }  
                      if (value == 1){
                        return(
                          <a className="nav-link">
                            <Link 
                                to="/profile"
                                className={"nav-link" + 
                                (url === "/profile" ?" active" : "") + 
                                (url === "/edit" ?" active" : "")}>
                                  Profile
                            </Link>
                          </a>
                            )
                          } 
                    })()}
                   
                   <a className="nav-link">
                   <Link 
                      to="#"
                      className={"nav-link"} onClick={logout}>Logout</Link>
                   </a>

                 </div>

                 <div className="navbar-nav-mobile">
                    <a className="nav-link" >
                    <Link 
                      to="/"
                      className={"nav-link" + 
                      (url === "/" ?" active" : "") + 
                      (url === "/regis" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Dashboard</span>
                    </Link>
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/vacancy"
                      className={"nav-link" + 
                      (url === "/vacancy" ?" active" : "") + 
                      (url === "/detail/:id" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Vacancy</span>
                    </Link>   
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/liststatus"
                      className={"nav-link" + 
                      (url === "/status" ?" active" : "")+
                      (url === "/liststatus" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Status</span>
                    </Link>
                    </a>


                    {(() => {
                      if (value == 0)  {
                        return (
                           <a className="nav-link" onClick={fireAlert3}>
                           <Link 
                               
                               className={"nav-link" + 
                               (url === "/profile" ?" active" : "") + 
                               (url === "/edit" ?" active" : "")}>
                                <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Profile</span>
                           </Link>
                         </a>
                              );
                          }  
                      if (value == 1){
                        return(
                          <a className="nav-link">
                            <Link 
                                to="/profile"
                                className={"nav-link" + 
                                (url === "/profile" ?" active" : "") + 
                                (url === "/edit" ?" active" : "")}>
                                <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Profile</span>
                            </Link>
                          </a>
                            )
                          } 
                    })()}

                    <a className="nav-link">
                    <Link 
                      to="#"
                      className={"nav-link"}  onClick={logout}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Logout</span>
                    </Link>
                    </a>

                 </div>
               </div>
         </div>
       </nav>
      
     ) : (
       <nav className="navbar navbar-expand-lg navbar-light fixed-top">
         <div className="container-fluid container">
           <a className="navbar-brand"><img src={logo} alt="Logo"/></a>
             <button  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
               <span className="navbar-toggler-icon"></span>
             </button>
               <div className="collapse navbar-collapse justify-content-end"   id="navbarNavAltMarkup">
                 <div className="navbar-nav" >
                    <a className="nav-link" >
                    <Link 
                      to="/"
                      className={"nav-link" + 
                      (url === "/" ?" active" : "") + 
                      (url === "/verifikasi" ?" active" : "")}>
                        Home
                    </Link>
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/vacancy"
                      className={"nav-link" + 
                      (url === "/vacancy" ?" active" : "")}>
                        Vacancy
                    </Link>
                    </a>

                    <a className="nav-link mt-2" href="https://anyargroup.co.id/" target="_blank">
                      About Us
                    </a>
                         
                    <a className="nav-link">
                    <Link 
                      to="/register"
                      className={"nav-link" + 
                      (url === "/register" ?" active" : "")}>
                        Register
                    </Link>
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/login" 
                      className={"nav-link" + 
                      (url === "/login" ?" active" : "")+
                      (url === "/resetpass" ?" active" : "")}>
                        Login
                    </Link>
	     </a>

                 </div>

                 <div className="navbar-nav-mobile" >
                 
                    <a className="nav-link" >
                    <Link 
                      to="/"
                      className={"nav-link" + 
                      (url === "/" ?" active" : "") + 
                      (url === "/verifikasi" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Home</span>
                    </Link>
                    </a>

                    <a className="nav-link">
                    <Link 
                      to="/vacancy"
                      className={"nav-link" + 
                      (url === "/vacancy" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Vacancy</span>
                    </Link>
                    </a>

                    <a className="nav-link mt-2"  href="https://anyargroup.co.id/" target="_blank">
                    <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">About Us</span>
                    </a>
                         
                    <a className="nav-link">
                    <Link 
                      to="/register"
                      className={"nav-link" + (url === "/register" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Register</span>
                    </Link>
                    </a>

                    <a className="nav-link">
                     <Link 
                      to="/login" 
                      className={"nav-link" + 
                      (url === "/login" ?" active" : "")+
                      (url === "/resetpass" ?" active" : "")}>
                      <span data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">Login</span>
                    </Link>                    </a>

                 </div>
               </div>
         </div>
       </nav>
     )}  
     {loader}
   </div>
  );
}

export default Navbar;

