import './App.css';

import Navbar from './components/Navbar'
import Login from './components/Login'
import Register from './components/Regis1'
import Dashboard from './components/Dashboard'
import CekEmail from './components/CekEmail'
import Footer from './components/Footer'
import Vacancy from './components/Vacancy'
import ListStatus from './components/ListStatus'
import Status from './components/Status'
import Profile from './components/Profile'
import EditProfile from './components/EditProfile'
import Detail_vacancy from './components/Detail_vacancy'
import InputData from './components/InputData'
import Wishlist from './components/Wishlist'
import Changepass from './components/ChangePass'
import Resetpass from './components/Resetpass'
import Forgetpass from './components/ForgetPass'
import ScrollToTop from './components/ScrollBtn'
import Attedance from './components/Attedance'
import Verifikasiemail from './components/Cekforgetpass'
import Cekverif from './components/CekVerif'
import Document from './components/Document'
import Datapribadi1 from './components/Datapribadi1'
import Datapribadi2 from './components/Datapribadi2'
import Datapribadi3 from './components/Datapribadi3'
import Datapribadi4 from './components/Datapribadi4'
import FormExternal from './components/FormExternal'
import { handelRightClick } from './components/AppUtility'
import axios from 'axios';
import { useHistory } from "react-router";
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';


function App() {
  const token = localStorage.getItem('token');
  const history = useHistory();

  document.addEventListener('contextmenu', handelRightClick);

  document.addEventListener("mousemove", () =>{ 
    localStorage.setItem('lastActvity', new Date())
  });
  document.addEventListener("click", () =>{ 
    localStorage.setItem('lastActvity', new Date())
  });
  document.addEventListener("scroll", () =>{ 
    localStorage.setItem('lastActvity', new Date())
  });
 

  let timeInterval = setInterval(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    let lastAcivity = localStorage.getItem('lastActvity')
    var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
    var seconds = Math.floor((diffMs/1000));
    var minute = Math.floor((seconds/60));
   //console.log(seconds +' sec and '+minute+' min since last activity')
    if(minute == 20){
    //console.log('No activity from last 10 minutes... Logging Out')
      clearInterval(timeInterval)
      localStorage.removeItem('token');
      // history.push("/login");
      window.location.href = '/login';
    }
  
  },1000)

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/presensi" component={Attedance} />
            <div>
              <Navbar />
                <Route exact path="/" component={Dashboard} />
                <Route exact path="/verifikasi/:encoded" component={CekEmail} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/vacancy" component={Vacancy} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/status/:id" component={Status} />
                <Route exact path="/liststatus" component={ListStatus} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/edit" component={EditProfile} />
                <Route exact path="/detail/:id" component={Detail_vacancy} />
                <Route exact path="/regis" component={InputData} />
                <Route exact path="/wishlist" component={Wishlist} />
                <Route exact path="/changepass" component={Changepass} />
                <Route exact path="/resetpass" component={Resetpass} />
                <Route exact path="/forgetpass" component={Forgetpass} />
                <Route exact path="/verifemail" component={Verifikasiemail} />
                <Route exact path="/emailcek" component={Cekverif} />
                <Route exact path="/document" component={Document} />
                <Route exact path="/family/:encoded" component={Datapribadi1} />
                <Route exact path="/education/:encoded" component={Datapribadi2} />
                <Route exact path="/job/:encoded" component={Datapribadi3} />
                <Route exact path="/medical/:encoded" component={Datapribadi4} />
                <Route exact path="/formext" component={FormExternal} />
                <Route exact path="/error" component = {Error} />
                <Footer />
      
            </div>
        </Switch>
	      <ScrollToTop />
      </Router>   
 
    
    </div>
  
  )
 
  
}

export default App;


