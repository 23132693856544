import "../css/style.scss";
import "../css/liststatus/liststatus.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Full from './FullLoad';
import status_vacancy from "../img/status_vacancy.png";


function ListStatus() {
  const [status, setStatus] = useState([]);
  const [apply, setAplly] = useState([]);
  const [user, setUser] = useState([]);
  const [loader, showLoader, hideLoader] = Full();
  const token = localStorage.getItem("token");

  const fetchData = async () => {
    showLoader();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios.get("https://hris.anyargroup.co.id/api/profile")
    .then((response) => {
      setStatus(response.data.apply);
      setAplly(response.data.apply);
      setUser(response.data.data);
      hideLoader();
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

return (
  <div>
       {(() => {
            if (apply == 0) {
              return (
                <div className="status">
                <div className="container container-status">
                  <div className="row justify-content-center row-status ">
                    <div className="col-lg-3 col-md-9 col-sm-12 col-xs-12 col-status">
                      <img src={status_vacancy} alt="Hria" className="status-image" />
                      <h5 className="status-title">Status Vacancy</h5>
                    </div>
                      <div className="row  row-text justify-content-center">
                        <div className="col-xl-12 col-lg-8 col-text">
                          <p className="text-muted">
                            Halo.. <span className="user">{user.name}</span> haven't applied for a job  .
                          </p>
                          <Link className="click" to="/vacancy">Click Here</Link>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              
              );
            } else if (apply !== 0) {
              return (
                <div className="liststatus">
                <div className="container container-liststatus">
                  <div className="row row-liststatus">
                  <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-img-liststatus">
                      <div className="card card-img-liststatus">
                        <img className="img-liststatus" src={status_vacancy}></img>
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-liststatus">
                    {status&&status.map((statuss,index) => (
                        <div key={index} className="card card-liststatus">
                          <div className="row justify-content-between row-card-liststatus">
                            <div className="col-xl-7 col-lg-7 col-md-7 col-7 col-card-liststatus">
                            <h5 className="title-vacancy">{statuss.loker.lowongan_kerja}</h5>
                            <h5 className="title-status">Status : <span>{statuss.status}</span></h5>
                            </div>
                            <div className="col-3 col-lg-3 col-md-3 col-5 col-card-btn">
                              <Link to={`/status/${statuss.id}`} className="link-btn">
                                <div className="btn btn-detail">Detail</div>
                              </Link>
                            </div>
                          </div>
                        
                        </div>
                    ))}
                     
                    </div>
                  </div>
                </div>
               </div>
              );
            }
          })()}
       
       {loader}
       </div>
       
        );
    
 
}

export default ListStatus;

