import '../css/style.scss';
import '../css/email/email.scss';
import verif from '../img/verif.png';
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";
import {decode as base64_decode, } from "base-64";
import { useHistory } from 'react-router';


function Email() {

  const params = useParams();
  const [show, setShow] = useState(false);
  
  const [seconds, setSeconds] = React.useState(10);
  const decoded = base64_decode(params.encoded);

  const history = useHistory();

  useEffect(() => {
    setTimeout(() => setShow(true), 10000);
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds('');
    }
    
  });

  
  const onSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append("email", decoded);
        await axios.post('https://hris.anyargroup.co.id/api/resendemail', formData)
        .then((response) => {
          if(response.data.message === "Success!! "){
            history.push('/emailcek');
        }
        })
        .catch((error) => {
          
        })
    };

  return (
    <div className="verifikasi">

       <div className="container">
       <div className="row justify-content-center row-verifikasi ">
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-verifikasi">
         <img src={verif} alt="Hria" className="verifikasi-image"/>
         <h5 className="verifikasi-title">Verify your email</h5>
         </div>
       </div>
       <h6 className="message">Almost there! We’ve sent a verification email to <span className="message-1">{decoded}</span><br></br>
             you need to verify your email address to login Anyar Career.
             
       </h6>
       {show ? null :  <h5 className="countdown">Time for Verify <span className="hours">{seconds}</span> </h5> }
      
       {show ?
       <form onSubmit={onSubmit}>
       
       <div className="verifikasi-btn"  >
     <button className="verifikasi-btn-1" type="submit" > <span>Resend Email</span></button> 
       </div>
       
       </form>:  null}
     </div>

     
        
        
    </div>
  );
}

export default Email;

