import "../css/style.scss";
import "../css/detailvacancy/detailvacancy.scss";
import { Link } from "react-router-dom";
import location from "../img/place.png";
import work from "../img/work1.png";
import save from "../img/save.png";
import share from "../img/share.png";
import calendar from "../img/calendar.png";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";
import Swal from "sweetalert";
import Swal2 from "sweetalert2";
import Swal3 from "sweetalert2";
import Swal4 from "sweetalert2";
import Full from "./FullLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
const heart = <FontAwesomeIcon icon={faHeart} />;


function Detail() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [wish, setWish] = useState(false);
  const [isLogin, SetIsLogin] = useState(false);
  const params = useParams();
  const [allw, setAll] = useState([]);
  const [allwish, setAllwish] = useState([]);
  const [userLamar, setIdlamar] = useState([]);
  const [profile, setProfile] = useState([]);
  const [euweh, setEuweh] = useState([]);
  const [block, setBlock] = useState([]);
  const token = localStorage.getItem("token");
  const history = useHistory();
  const parse = require("html-react-parser");
  const [loader, showLoader, hideLoader] = Full();
  const [loader2, showLoader2, hideLoader2] = Full();

  const fireAlert = () => {
    Swal2.fire({
      title: "You don't have account",
      showConfirmButton: false,
      footer: '<a style="text-decoration:none; color:#1572a1; font-weight:500;" href="/register">Please create account</a>',
      icon: "warning",
    });
  };

  const fireAlert3 = () => {
    Swal3.fire({
      icon: "warning",
      title: "Your data is not complete",
      showConfirmButton: false,
      footer: '<a style="text-decoration:none; color:#1572a1; font-weight:500;" href="/regis">Please complete data vacancy</a>',
    });
  };

  const fireAlert4 = () => {
    Swal4.fire({
      icon: "warning",
      title: "Oops...",
      showConfirmButton: false,
      text: "Your vacancy is taken",
    });
  };

  const fireAlert5 = () => {
    Swal4.fire({
      showConfirmButton: false,
      width:400,
      html:
          '<a class="btn button" target="_blank" style="border:none; color:#25D366; font-size:50px;" alt="Share Page on Whatsapp" href="https://api.whatsapp.com/send?text=https://karir.anyargroup.co.id/vacancy">'+
          '<i  class="fa-brands fa-whatsapp"></i>'+
          '</a>'+
          '<a class="btn button" target="_blank" style="border:none; color:#1D9BF0; font-size:50px;" alt="Share Page on Twitter" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fkarir.anyargroup.co.id/vacancy">'+
          '<i  class="fa-brands fa-twitter"></i>'+
          '</a>'+
          // '<a class="btn button" target="_blank" style="border:none; color:#C13584; font-size:50px;" alt="Share Page on Instagram"  href="https://www.instagram.com/send?story=https://karir.anyargroup.co.id/vacancy">'+
          // '<i class="fa-brands fa-instagram"></i>'+
          // '</a>'+
          '<a class="btn button" target="_blank" style="border:none; color:#4267B2; font-size:50px;" alt="Share Page on Facebook" href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fkarir.anyargroup.co.id/vacancy">'+
          '<i class="fa-brands fa-facebook"></i>'+
          '</a>',
   
    });
  };

  const fetchData = async () => { 
    showLoader();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios.get("https://hris.anyargroup.co.id/api/profile")
      .then((response) => {
        // Tambahkan log untuk melihat respons lengkap dari API
        console.log("Response dari API /profile:", response);
  
        if (response.data.lamar) {
          // console.log("ieu nu bener", response.data.lamar);
          setIdlamar(response.data.lamar);
          setEuweh(1);
      } else {
          console.log("entong kadie");
          setIdlamar(null);
          setEuweh(0);
      }
        
        setProfile(response.data.data);
        const formData1 = response.data.data.id;
        const formData2 = params.id;
  
        // Fetch status detail apply
        axios
          .get("https://hris.anyargroup.co.id/api/status/detlistapply" + "/" + formData1 + "/" + formData2)
          .then((res) => {
            console.log("Response dari API /detlistapply:", res);
            setBlock(res.data.data.length);
            hideLoader();
          });
      })
      .catch((error) => {
        console.log("Error saat fetch API /profile:", error);
        hideLoader();
      });
  };

  //useEffect hook
  useEffect(() => {
    showLoader2();
    axios
      .get(`https://hris.anyargroup.co.id/api/apiloker/${params.id}`)
      .then((res) => {
        hideLoader2();
        setAll(res.data.data);
      })
      .catch((err) => {
        
      });

    if (token) {
      SetIsLogin(true);
      history.push(`/detail/${params.id}`);
    } else {
      SetIsLogin(false);
    }
    
    fetchData();
  }, [params.id]);

  
  const createProduct = async () => {
      const formData1 = params.id;
      const formData2 = userLamar.id;
      const formData3 = profile.id;

    await axios
      .post(
        "https://hris.anyargroup.co.id/api/apiloker/saveaplly/" +
          formData1 +
          "/" +
          formData2 +
          "/" +
          formData3
      )
      .then((res) => {     
      })
      .catch((err) => {
        Swal({
            title: "Done!",
            text: "Apply Job Success",
            icon: "success",
            timer: 2000,
            button: false,
          });
          history.push("/liststatus");
      });
  };

  const createWish = async () => {
    const formData = new FormData();
    formData.append("loker_id" ,params.id);
    formData.append("pelamar_id",userLamar.id);
  await axios
    .post(
      "https://hris.anyargroup.co.id/api/wish",formData)
    .then((res) => { 
      setWish(res.data.code)
    })
    .catch((err) => {
     
    });
  };

const listall = allwish.map(allwishh => allwishh.loker_id);
const selectedItem =  params.id;
const listItems = listall.find(allwishs => allwishs == selectedItem  );
const listall2 = allw.map(all1 => all1.id);
const allss = listall2.find(all2 => all2 == selectedItem  );


useEffect(() => {
  axios
    .get(`https://hris.anyargroup.co.id/api/wishlist`)
    .then((res) => {
      setAllwish(res.data.data );
    })
    .catch((err) => {  
    });

}, []);

  return (
    <>
      <div className="detail">
          <div>
            {allw &&
              allw.map((alls, index) => (
                <div key={index}>
                  <img
                    src={`https://hris.anyargroup.co.id/storage/images/posts/${alls.dok_header}`}
                    className="img-detail"
                  ></img>
                  <div className="container container-detail">
                    <div className="detail-content">
                      <div className="row row-detail">
                        <div className="col-lg-6 col-xl-3 col-md-6 col-sm-12 col-xs-12 col-detail">
                          <div className="card card-detail">
                            <img
                              src={`https://hris.anyargroup.co.id/storage/images/posts/${alls.dok_poster}`}
                              className="img-detail-1"
                            ></img>
                          </div>
                        </div>
                      </div>

                      <div className="row row-detail-title">
                        <div className="col-lg-12 col-detail-title-1">
                          <h5 className="title-head">{alls.lowongan_kerja}</h5>
                        </div>
                        <div className="col-lg-12 col-detail-title-2">
                          <h5 className="title-text-1">
                            <img src={location} className="img-loc"></img>
                            {alls.cabang.nama} - {alls.cabang.alamat}, Indonesia
                          </h5>
                        </div>
                        <div className="col-lg-12 col-detail-title-3">
                          <div className="row row-detail-title-3">
                            <div className="col-xl-6 col-lg-12 col-md-12 col-title-text-1">
                              <h5 className="title-text-1">
                                <img src={work} className="img-work"></img>Full
                                Time
                              </h5>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 col-title-text-2">
                              <h5 className="title-text-2">
                                <img src={calendar} className="img-work"></img>
                                Posted at : {alls.tanggal}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-between row-button">

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-button-1" >
                        {isLogin ? (
                            <div>
                              {(() => {
                                  if (euweh === 0)  {
                                  return (
                                    <div>
                                        <div onClick={fireAlert3}>
                                          <div className="btn btn-aplly">
                                            <h5 className="text-aplly">
                                              Apply
                                            </h5>
                                          </div>
                                        </div>
                                    </div>
                                  );
                                }  
                              if (euweh === 1){
                                  return(
                                      <div onClick={() => setShow (createProduct()) }>
                                          <Link to={`/status/apply/${alls.id}`}>
                                          <div className="btn btn-aplly" style={{height:"45px"}}><h5 className="text-aplly">Apply</h5></div>
                                          </Link>
                                          
                                      </div>
                                      
                                  )
                                } 
                              })()}
                              {!show ? 
                              <div>
                                  {(() => {
                                            if (block === 1  ){
                                                  return(
                                                      <div style={{marginTop: "-55px"}}>
                                                      <div onClick={fireAlert4}>
                                                        <div className="btn btn-apllied" >
                                                          <h5 className="text-apllied" >
                                                          You Has Apllied
                                                          </h5>
                                                        </div>
                                                      </div>
                                                  </div>
                                                  )
                                                }
                                            })()}
                                             {loader}
                              </div>
                              : 
                              null}
                            </div>
                            
                         ):(
                            <Link  onClick={fireAlert}>
                            <div>
                                <div className="btn btn-aplly"><h5 className="text-aplly">Apply</h5></div>
                            </div>
                            </Link>
                          )}
                            </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-button-2">
                          <div className="det-btn">
                          {isLogin ? (
                       <div>
                       {!show2 ?  
                       <div onClick={() =>[setShow1 (createWish()),setShow2(true)] } className="btn btn-save">
                         {(() => { 
                               if (allss !== listItems ){
                                   return(
                                     <h5 className="text-save">
                                       <img src={save} className="img-save"></img>
                                         Save
                                       </h5>
                                        )
                             } if (allss === listItems   ){
                                   return(
                                       <h5 className="text-save"><span style={{fontSize:"18px",color:"red"}} >{heart} </span>Save</h5>
                                        )
                                      }
                                  })()} 
                         </div> : null }
                                               
                         {!show1 ? null : 
                           <div onClick={() => setShow2 (createWish()) } className="btn btn-save">
                               {(() => {
                                       if (wish === "reg1"  ){
                                           return(
                                             <h5 className="text-save">
                                               <img src={save} className="img-save"></img>
                                                 Save
                                             </h5>
                                                 )
                                     } if (wish === "reg2"  ){
                                           return(
                                             <h5 className="text-save"><span style={{fontSize:"18px" ,color:"red"}}>{heart} </span>Save</h5>
                                                 )
                                       }
                                       })()}
                                         
                                       </div> 
                                                }
                                                  
                       <Link onClick={fireAlert5}>
                       <div className="btn btn-share" >
                         <h5 className="text-share">
                           <img src={share} className="img-share"></img>
                           Share
                         </h5>
                       </div>
                     </Link>
                     </div>
                   
                 
                          ):(      <Link onClick={fireAlert5}>
                            <div className="btn btn-share" >
                              <h5 className="text-share">
                                <img src={share} className="img-share"></img>
                                Share
                              </h5>
                            </div>
                          </Link>)
                          }

                          </div>

                        </div>

                      </div>
                      <hr></hr>

                      <div className="row row-rekrut">
                        <div className="col-lg-12 col-deskripsi">
                          <h5 className="title-deskripsi">Job Description</h5>
                          <ul className="list-deskripsi">
                            {parse(alls.deskripsi)}
                          </ul>
                        </div>
                        <div className="col-lg-12 col-requirement">
                          <h5 className="title-requirement">Requirement</h5>
                          <ul className="list-requirement">
                            {parse(alls.kualifikasi)}
                          </ul>
                        </div>

                        <div className="col-lg-12 col-summary">
                          <h5 className="title-summary">Jobs Summary</h5>
                          <div className="container container-summary">
                            <div className="row row-summary">
                              <div className="col-lg-4 col-summary">
                                <h5 className="title-summary">Job Level</h5>
                                <h5 className="text-summary">
                                  {alls.level_job} ( Non-management /
                                  Non-Supervisor)
                                </h5>
                              </div>
                              <div className="col-lg-8 col-summary">
                                <h5 className="title-summary">Education</h5>
                                <h5 className="text-summary">
                                  {alls.pendidikan}
                                </h5>
                              </div>
                              <div className="col-lg-4 col-summary">
                                <h5 className="title-summary">Experience</h5>
                                <h5 className="text-summary">
                                  {alls.pengalaman} - Tahun
                                </h5>
                              </div>
                              <div className="col-lg-4 col-summary">
                                <h5 className="title-summary">Job Category</h5>
                                <h5 className="text-summary">
                                  {alls.kategory_job}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {loader2}
      </div>
     
    </>
  );
}

export default Detail;
