import React from 'react'
import "../css/style.scss";
import rkm from "../img/rkm.png";

const LoadSpin = () => {
  return (
    <div className="fp-container">
        <div className="fp-loader">
        <div class="swapping-squares-spinner">
  <div class="square"></div>
  <div class="square"></div>
  <div class="square"></div>
  <div class="square"></div>
</div>
        </div>
    </div>
  )
}

export default LoadSpin
