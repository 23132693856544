import "../css/style.scss";
import location from "../img/location.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Full from "./FullLoad";
import wishlist from "../img/wishlist.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

function Vacancy() {
  const [loader, showLoader, hideLoader] = Full();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [lok, setLok] = useState([]);
  const [nama, setNama] = useState([]);
  const [cardvacancy, setCardvacancy] = useState([]);
  const [cabang, setCabang] = useState([]);
  const [all, setAll] = useState([]);
  const token = localStorage.getItem("token");
  const [isLogin, SetIsLogin] = useState(false);

  const fetchData3 = async () => {
    showLoader();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  useEffect(() => {
    if (token) {
      SetIsLogin(true);
    } else {
      SetIsLogin(false);
    }
    fectData();
    fectData1();
    fectData2();
    fetchData3();
  }, []);

  const fectData = async () => {
    const response = await axios
      .get("https://hris.anyargroup.co.id/api/apipt")
      .then((response) => {
        setCardvacancy(response.data.data);
        setNama(response.data.data);
        console.log("ini ", response.data.data);
      });
  };

  const fectData1 = async () => {
    showLoader();
    const response = await axios
      .get("https://hris.anyargroup.co.id/api/apiloker")
      .then((response) => {
        setAll(response.data.data);
        hideLoader();
      });
  };

  const fectData2 = async () => {
    showLoader();
    const response = await axios
      .get("https://hris.anyargroup.co.id/api/cabang")
      .then((response) => {
        setCabang(response.data.data);
        hideLoader();
      });
  };

  const Results = () => (
    <div className="row justify-content-center row-vac-all">
      {all.slice(0, 10).map((alls, index) => (
        <div
          className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 col-vac-all"
          key={index}
        >
          <div className="card card-vac-all">
            <div className="row row-card-vac-all">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-card-vac-all-icon">
                <div className="card-content-vac">
                  <img
                    src={`https://hris.anyargroup.co.id/storage/images/posts/${alls.dok_poster}`}
                    className="img-vac"
                  />
                </div>
              </div>

              <div className="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-card-vac-all-text">
                <div className="title-vac">
                  <h5 className="title-1">{alls.lowongan_kerja}</h5>
                  <h5 className="title-2">
                    <img src={location} className="img-vac"></img>
                    {alls.cabang.nama} {alls.cabang.alamat}, Indonesia
                  </h5>
                </div>
              </div>

              <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-card-vac-all-btn">
                <Link
                  to={`/detail/${alls.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="btn btn-detail">
                    <h5 className="text-detail">{eye} Detail</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const Results1 = () => (
    <div className="row justify-content-center row-vac-all">
      {all.map((alls, index) => {
        if (alls.cabang.fk_nama_perusahaan == show) {
          return (
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 col-vac-all"
              key={index}
            >
              <div className="card card-vac-all">
                <div className="row row-card-vac-all">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-card-vac-all-icon">
                    <div className="card-content-vac">
                      <img
                        src={`https://hris.anyargroup.co.id/storage/images/posts/${alls.dok_poster}`}
                        className="img-vac"
                      />
                    </div>
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-card-vac-all-text">
                    <div className="title-vac">
                      <h5 className="title-1">{alls.lowongan_kerja}</h5>
                      <h5 className="title-2">
                        <img src={location} className="img-vac"></img>
                        {alls.cabang.nama} {alls.cabang.alamat}, Indonesia
                      </h5>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-card-vac-all-btn">
                    <Link
                      to={`/detail/${alls.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="btn btn-detail">
                        <h5 className="text-detail">{eye} Detail</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
        }
      })}
    </div>
  );

  const Results2 = () => (
    <div className="row justify-content-center row-vac-all">
      {all.map((alls, index) => {
        if (alls.cabang.id == show1) {
          return (
            <div
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 col-vac-all"
              key={index}
            >
              <div className="card card-vac-all">
                <div className="row row-card-vac-all">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-card-vac-all-icon">
                    <div className="card-content-vac">
                      <img
                        src={`https://hris.anyargroup.co.id/storage/images/posts/${alls.dok_poster}`}
                        className="img-vac"
                      />
                    </div>
                  </div>

                  <div className="col-xl-8 col-lg-8 col-md-7 col-sm-7 col-card-vac-all-text">
                    <div className="title-vac">
                      <h5 className="title-1">{alls.lowongan_kerja}</h5>
                      <h5 className="title-2">
                        <img src={location} className="img-vac"></img>
                        {alls.cabang.nama} {alls.cabang.alamat}, Indonesia
                      </h5>
                    </div>
                  </div>

                  <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-card-vac-all-btn">
                    <Link
                      to={`/detail/${alls.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="btn btn-detail">
                        <h5 className="text-detail">{eye} Detail</h5>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );

  return (
    <>
      <div className="vacancy">
        <div className="container vacancy-content">
          <div className="vacancy-title">
            <h4 className="vacancy-title-1">
              <span className="vacancy-garis">Available Vacancy</span>
            </h4>
          </div>

          <div className="row row-wish-form">
            <div className="col-xl-6 col-lg-6 col-wish-form">
              {isLogin ? (
                <div className="row">
                  <div className="col-xl-4 col-sm-12 col-md-6">
                    <div className="wishlist-button">
                      <Link to={"/wishlist"}>
                        <button className="btn-wishlist">
                          <h5 className="text-wishlist">
                            <img src={wishlist} className="img-wishlist"></img>
                            Wishlist
                          </h5>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="col-xl-6 col-lg-6 col-wish-form">
              <form className="form-vacancy">
                <div className="row justify-content-end row-vacancy">
                  <div className="col-lg-6 col-sm-12 col-md-6 col-vacancy-2">
                    <select
                      data-val="true"
                      className="search-2"
                      onChange={(e) => setLok(e.target.value)}
                      placeholder="Job Title,Company..."
                    >
                      <option selected>Location ...</option>
                      {cabang.map((cabangs) => (
                        <option key={cabangs.id} value={cabangs.id}>
                          {cabangs.nama}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-lg-6 col-sm-12 col-md-6 col-vacancy-4">
                    <a href="#ini" style={{ textDecoration: "none" }}>
                      <div
                        onClick={() => [setShow1(lok), setShow(true)]}
                        className="btn btn-search"
                        type="submit"
                      >
                        <h5 className="text-search">Search</h5>
                      </div>
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* api pt */}
          <div className="row justify-content-center row-vacancy-all">
            {cardvacancy.map((cardvacancys, index) => (
              <div
                className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-2 col-6 col-vacancy-all"
                key={index}
              >
                <a href="#ini" style={{ textDecoration: "none" }}>
                  <div
                    onClick={() => [setShow(cardvacancys.id), setShow1(true)]}
                    for="ini"
                    className="card card-vacancy-all active"
                  >
                    <div className="card-content-vacancy">
                      <img
                        src={`https://hris.anyargroup.co.id/storage/pt/${cardvacancys.logo}`}
                        className="img-icon"
                      />
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>

          <div id="ini">
            <div className="vacancy-title">
              <h4 className="vacancy-title-1">
                Vacancy
                {all.map((alls, index) => {
                  if (alls.cabang.fk_nama_perusahaan == show) {
                    return <div key={index}>{alls.nama}</div>;
                  } else {
                  }
                })}
              </h4>
              <hr></hr>
              <div className="row">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <img
                  className="mt-2"
                    src={`${process.env.PUBLIC_URL}/anyargroup.png`}
                    style={{ width: "100%" }}
                  ></img>
                </div>
                <div className="col-8 text-lamar">
                  <h3
                    style={{
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: "15px",
                      color: "#000000",
                      marginTop: "14px",
                      textDecorationLine: "none",
                      paddingLeft: "5px",
                    }}
                  >
                    Khusus Pelamar External (Brand)
                  </h3>
                  <p
                    class="title-2"
                    style={{
                      fontFamily: "Nunito Sans",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "13px",
                      color: "#9d9d9d",
                      marginTop: "16px",
                    }}
                  >
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFtSURBVHgB5VTBbYNAEDxwHn74QQmkA7sDp4LEL8MLqAC7gogO7ApQXgjEg1TgEkIJlMCLH5BZCUtkdYcPJ5/II520t3u7c7e3u0L8dxi3DsRxbK1Wq7DrOh9be1CXhmGUbdtGrutWdxPkee4hyAmipThS930fOY5zmk2QZVkIZ6Ujg7/f7z+0CZIksU3T/BLqm3PUTdM8B0FQc4MpO71YLN4lwSkdZ1qQK2azlsvlQRZLSoAga6aq8Mkb5PpAC/ILEf4IZJqeNgGwZoSf42ohGTqec1vMIPgzqAiq8QY179HHX/ckQ/fKfEpZoCeZklKCAOFIZSHHlzRNi2HvC1YE8JlFUDACgo0lrZTBR78PCGi0C5y2Qg8lGm0jMyg/meaM0ATK9qyyTc4izVcUuP1OZZwsU7wiEKyhOHD745R9kmBoqGgi+O/G9RWKVCk/dgytTpakimbTTsdXi4DSwAIeb6XmLqCT3zAmtuKh8A3Gmq8sbFhb2wAAAABJRU5ErkJggg=="
                      class="img-vac"
                    ></img>
                    All Cabang RKM Jl. A. Yani No.343, Sukamaju, Kec. Cibeunying Kidul, Kota Bandung, Jawa Barat 40121, Indonesia
                  </p>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <div>
                  <style>
                    {`
                      @media (max-width: 575.98px) {
                        .btn-detail {
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                        }

                        .text-lamar {
                          text-algin:center;
                        }
                      }
                    `}
                  </style>
                  </div>
                  <div
                    class="btn btn-detail mt-5"
                    style={{
                      padding: "1px 6px",
                      border: "none",
                      backgroundColor: "#1572a1",
                    }}
                  >
                    <a
                      href="/formext"
                      className="btn btn-detail"
                      style={{
                        border: "none",
                        backgroundColor: "#1572a1",
                        textDecoration: "none",
                      }}
                    >
                      <h5
                        class="text-detail"
                        style={{
                          padding: "4px 14px",
                          margin: "0 auto",
                          color: "#fff",
                          fontFamily: "Nunito Sans",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        {eye}   Lamar
                      </h5>
                    </a>
                  </div>
                </div>
              </div>
              <hr style={{
                border: "1px solid #1572a1"
              }}></hr>
            </div>
            <h2 className="vac-not">Vacancy not available</h2>
          </div>

          {!show ? <Results /> : <Results1 />}

          {!show1 ? null : <Results2 />}
        </div>
      </div>
      {loader}
    </>
  );
}

export default Vacancy;
