import '../css/style.scss';
import '../css/cekforgetpass/cekforgetpass.scss';
import verif from '../img/verifemail.png';
import React, { useState, useEffect } from 'react';


function Cekforgetpass() {
  return (
    <div className="verifikasi">
       <div className="container">
       <div className="row justify-content-center row-verifikasi ">
         <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-verifikasi">
         <img src={verif} alt="Hria" className="verifikasi-image"/>
         <h5 className="verifikasi-title">Link reset password has been sent to your email</h5>
         </div>
       </div>
       <h6 className="message">We’ve sent a link reset password to your Email. <span className="message-1"></span><br></br>
       you need to click button link for reset your password.
       </h6>
     </div>
    </div>
  );
}

export default Cekforgetpass;

