import '../css/style.scss';
import '../css/login/login.scss';
import axios from 'axios';
import imglogin from '../img/image_login.png';
import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";  
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eye1 = <FontAwesomeIcon icon={faEyeSlash} />;

function Login() {

    const history = useHistory();
    // const { register, formState: { errors }, handleSubmit, getValues  } = useForm();
    const [isLoading, setLoading] =  useState(false);
    const [email, setEmail] =  useState("");
    const [password, setPass] =  useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [errors, setError] = useState(false);
    const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    };

   
    useEffect(() => {
    if(localStorage.getItem('token')) {
       history.push('/login');
    }
    }, []);

    const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if(email.length==0||password.length==0){
      setError(true)
  }
  const formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);
        await axios.post('https://hris.anyargroup.co.id/api/login', formData)
        .then((response) => {
            
            localStorage.setItem('token', response.data.token);
            history.push('/');
            window.location.reload()
            setLoading(false)
            
        })
        .catch((error) => {
            if(error.response.data.code === "reg1"){
            
                Swal.fire({
                  title: "Please verify your email!!",
                  showConfirmButton: false,
                  icon: "warning",
                })
                
            }if(error.response.data.code === "reg2"){

              Swal.fire({
                title: "Your password is not correct!!",
                showConfirmButton: false,
                icon: "warning",
              })
              
          } if(error.response.data.message === "No query results for model [App\\Models\\User]."){
 
            Swal.fire({
              title: "You don't have an account!!",
              showConfirmButton: false,
              icon: "warning",
            })
            
        } 
           
            setLoading(false)
        })
    };

return (
    <div className="container" style={{ marginTop: "130px" }}>
        <div className="row justify-content-center row-login">
            <div className="col-md-6 col-lg-5 col-sm-12 col-xs-12 col-login">
                <div className="title-login">
                    <h2 className="title-content">Login To Your <span className="title-content-1">Account</span></h2>
                </div>
                    <div className="card card-login">
                        <div className="card-body">
                       
                            <form onSubmit={onSubmit}>
                                <div className="email mb-3">
                                    <label className="form-label">Email</label>
                                        <input  type="email"
                                                name="email"
                                            
                                                className="form-email form-control" 
                                                placeholder="Input Email"
                                                onChange={(e) => {
                                                    const re = /^[a-z\0-9\@\.\b]+$/;
                                                    if (e.target.value === "" || re.test(e.target.value)) {
                                                      setEmail(e.target.value);
                                                    }}}/>
                                         <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                                        {errors&&email.length<=0?
                                        <label>Email can't be Empty</label>:""}
                                        </div>
                                </div>
                       
                                <div className="password mb-3">
                                    <label className="form-label">Password</label>
                                        <div className="input-group grup-password">
                                            <input  
                                            name="password1" 
                                            className="form-password form-control" 
                                            type={passwordShown ? "text" : "password"} 
                                            placeholder="Input Password"
                                            onChange={e=>setPass(e.target.value)}/>
                                    
                                            {!passwordShown && <i className="eye1" onClick={togglePasswordVisiblity}>{eye1}</i>}{" "}
                                            {passwordShown && <i className="eye" onClick={togglePasswordVisiblity}>{eye}</i>}{" "}
                                        </div>
                                        <div style={{color:"red", fontSize:"14px", fontWeight:"700" ,fontfamily: "'Poppins', sans-serif"}}>
                          {errors&&password.length<=0?<label>Password can't be Empty</label>:""}
                          </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg col-pass">
                                        <Link to="/resetpass">
                                        <a className="forgot-password">Forgot your password?</a>
                                        </Link>
                                     
                                      
                                    </div>
                                </div>
                       
                                <div className="row row-btn">
                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-btn">
                                        <div className="button-login">
                                            {!isLoading &&  <button type="submit" className="btn btn-login" ><span className="title-login">Sign In</span></button> } 
                                            {isLoading &&  <button type="submit" className="btn btn-login" disabled><i className="fas fa-spinner fa-spin"></i> <span className="title-login">Sign In</span></button> } 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-btn">
                                        <div className="button-signup">
                                        <Link to="/register"><button className="btn btn-signup" ><span className="title-signup"> Sign Up</span></button></Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>  
            </div>

            <div className="col-md-6 col-lg-7 col-sm-12 col-xs-12 col-img-login">
                <div className="img-login">
                    <img class="img-login-content" src={imglogin} alt="Logo"/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Login;

