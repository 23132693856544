import '../css/style.scss';
import '../css/forgetpass/forgetpass.scss';
import axios from 'axios';
import forgot from '../img/forgot.png';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;
const eye1 = <FontAwesomeIcon icon={faEyeSlash} />;

function Resetpass() {

    const history = useHistory();
    const { register, formState: { errors }, handleSubmit, getValues  } = useForm();
    const [isLoading, setLoading] =  useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [validation, setValidation] = useState([]);
    const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    };

   
    useEffect(() => {
    if(localStorage.getItem('token')) {
       history.push('/forgetpass');
    }
    }, []);

    const onSubmit = async (e) => {
    setLoading(true);
        const values = getValues();
        await axios.post('https://hris.anyargroup.co.id/api/login', values)
        .then((response) => {
            localStorage.setItem('token', response.data.token);
            history.push('/');
            window.location.reload()
            setLoading(false)
        })
        .catch((error) => {
            setValidation(error.response.data);
            setLoading(false)
        })
    };

return (
    <div className="container" style={{ marginTop: "130px" }}>
        <div className="row justify-content-center row-reset">
            <div className="col-md-6 col-lg-5 col-sm-12 col-xs-12 col-reset">
                <div className="title-reset">
                    <h2 className="title-content">Create New <span className="title-content-1">Password</span>
                     </h2>
        
                </div>
                    <div className="card card-reset">
                        <div className="card-body">
                        { 
                            validation.message && (
                            <div className="alert alert-danger" style={{textAlign: "center"}}>
                                {validation.message}
                            </div>)
                        }
                            <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="password mb-3">
                                    <label className="form-label">Password</label>
                                        <div className="input-group grup-password">
                                            <input  
                                            name="password1" 
                                            className="form-password form-control" 
                                            type={passwordShown ? "text" : "password"} 
                                            placeholder="Input Password"
                                            {...register("password", {required:true ,minLength: 8 })}/>
                                    
                                            {!passwordShown && <i className="eye1" onClick={togglePasswordVisiblity}>{eye1}</i>}{" "}
                                            {passwordShown && <i className="eye" onClick={togglePasswordVisiblity}>{eye}</i>}{" "}
                                        </div>
                                        <span style={{ color: "red" , textAlign: "center", fontSize: "14px",   fontWeight: "700"}}> {errors.password?.type === 'required' && "Password can't be empty"}</span>
                                        <span style={{ color: "red" , textAlign: "center", fontSize: "14px",   fontWeight: "700"}}> {errors.password?.type === 'minLength' && "Password minimum 8 characters"}</span>
                                </div>
                       
                                <div className="password mb-3">
                                    <label className="form-label">Confirm Password</label>
                                        <div className="input-group grup-password">
                                            <input  
                                            name="password1" 
                                            className="form-password form-control" 
                                            type={passwordShown ? "text" : "password"} 
                                            placeholder="Confirm Password"
                                            {...register("password", {required:true ,minLength: 8 })}/>
                                    
                                            {!passwordShown && <i className="eye1" onClick={togglePasswordVisiblity}>{eye1}</i>}{" "}
                                            {passwordShown && <i className="eye" onClick={togglePasswordVisiblity}>{eye}</i>}{" "}
                                        </div>
                                        <span style={{ color: "red" , textAlign: "center", fontSize: "14px",   fontWeight: "700"}}> {errors.password?.type === 'required' && "Password can't be empty"}</span>
                                        <span style={{ color: "red" , textAlign: "center", fontSize: "14px",   fontWeight: "700"}}> {errors.password?.type === 'minLength' && "Password minimum 8 characters"}</span>
                                </div>

                                {/* <div className="row">
                                    <div className="col-lg col-pass">
                                      
                                        <a className="forgot-password" href="#"></a>
                                    </div>
                                </div> */}
                       
                                <div className="row row-btn">
                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-btn">
                                        <div className="button-reset">
                                            {!isLoading &&  <button type="submit" className="btn btn-reset" ><span className="title-reset">Send</span></button> } 
                                            {isLoading &&  <button type="submit" className="btn btn-reset" disabled><i className="fas fa-spinner fa-spin"></i> <span className="title-reset">Sign In</span></button> } 
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6 col-btn">
                                        <div className="button-signup">
                                        <Link to="/register"><button className="btn btn-signup" ><span className="title-signup"> Confirm Code</span></button></Link>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>  
            </div>

            <div className="col-md-6 col-lg-7 col-sm-12 col-xs-12 col-img-reset">
                <div className="img-reset">
                    <img class="img-reset-content" src={forgot} alt="Logo"/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Resetpass;
