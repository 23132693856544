import "../css/style.scss";
import "../css/dashboard/dashboard.scss";
import Full from "./FullLoad";
import human from "../img/human.png";
import rkm from "../img/rkm.png";
import tri from "../img/triwarna.png";
import ld from "../img/ld.png";
import ho from "../img/head.png";
import achiev from "../img/achiev.png";
import teamwork from "../img/teamwork1.png";
import engagement from "../img/engagement.png";
import adap from "../img/adap.png";
import motivation from "../img/motivation1.png";
import office1 from "../img/gatehring.jpg";
import office2 from "../img/donor.jpg";
import office3 from "../img/GO.jpg";
import office4 from "../img/jobfair.jpg";
import office5 from "../img/paint.jpg";
import { Link } from "react-router-dom";
import { handelRightClick } from "./AppUtility";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";

function Dashboard() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [isLogin, SetIsLogin] = useState(false);
  const [user, setUser] = useState([]);
  const [userLamar, setIdlamar] = useState([]);
  const [show, setShow] = useState(false);
  const [loader, showLoader, hideLoader] = Full();
  const token = localStorage.getItem("token");

  const imgs = [
    { id: 0, value: office1 },
    { id: 1, value: office2 },
    { id: 2, value: office3 },
    { id: 3, value: office4 },
    { id: 4, value: office5 },
  ];

  const [sliderData, setSliderData] = useState(imgs[0]);
  const handleclick = (index) => {
    const slider = imgs[index];
    setSliderData(slider);
  };

  const history = useHistory();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  useEffect(() => {
    if (token) {
      SetIsLogin(true);
      history.push("/");
    } else {
      SetIsLogin(false);
    }
    showLoader();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    //fetch user from Rest API
    axios.get("https://hris.anyargroup.co.id/api/profile").then((response) => {
      hideLoader();
      if (response.data.data) {
        setUser(response.data.data);
      } else if (response.data.lamar && response.data.lamar.length > 0) {
        setIdlamar(response.data.lamar[0]);
      }
    });
    return () => {
      document.addEventListener("contextmenu", handelRightClick);
    };
  }, []);
  document.removeEventListener("contextmenu", handelRightClick);

  return (
    <div className="content">
      {isLogin ? (
        <div className="container container-content">
          {(() => {
            if (userLamar) {
              return <div></div>;
            } else if (user) {
              return (
                <div className="announ">
                  {!show ? (
                    <div class="alert alert-warning announ-alert" role="alert">
                      <div className="row row-alert">
                        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-11 col-alert">
                          Please complete Your Data{" "}
                          <a
                            href="/regis"
                            class="alert-link"
                            style={{ textDecoration: "none" }}
                          >
                            Click here
                          </a>
                          .
                        </div>
                        <div
                          className="col-lg-1 col-md-1 col-sm-1 col-xs-1 col-close"
                          onClick={() => setShow(true)}
                        >
                          <button className="btn-close"></button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }
          })()}
          <div className="row row-content">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 content-title">
              <h2 className="title-1">
                HI <span className="title-2">{user.name}</span>
              </h2>
              <h1 className="title-1">Welcome to Anyar Career</h1>
              <p className="title-3">
                Join to be a part of the ATEAM family!<br></br>
                Anyar Group can be a place to grow, develop and find your true
                passion..
              </p>
              <Link to="/vacancy">
                <button className="button-join">
                  <a className="join">Ready To Join Us ?</a>
                </button>
              </Link>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 content-image">
              <img src={human} alt="Hria" className="img-dash" />
            </div>
          </div>

          <div className="ava-vacancy">
            <div className="ava-vacancy-title">
              <h2 className="ava-vacancy-title-1">Available Vacancy</h2>
            </div>
            <div className="row justify-content-center row-vacancy">
              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={rkm} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>
              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={tri} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>
              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={ld} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>

              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={ho} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="get">
            <div className="get-title">
              <h2 className="get-title-1">Let’s Talk About Perks...</h2>
            </div>
          </div>
          {showTopBtn && (
            <div className="skil">
              <div className="row justify-content-center row-skil">
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={achiev} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Achievement</h4>
                    <p className="content-skil">
                      We are principled to be able do develop <br></br>
                      employee career by recognize your passions<br></br>
                      and interests to improve employee<br></br>
                      themselve, their role, and the business in <br></br>
                      general.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={teamwork} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Team Work</h4>
                    <p className="content-skil">
                      Skill can win battles, but teamwork can won <br></br>
                      the war, In Anyar Group, we work as a team <br></br>
                      cause two always been better than one.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={engagement} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Engagement</h4>
                    <p className="content-skil">
                      Anyar Group believe that employees are out<br></br>
                      biggest asset, the essential factors for<br></br>
                      organization’s success. We value and put the<br></br>
                      well-being of your satisfaction as a priority.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={adap} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Adaptif</h4>
                    <p className="content-skil">
                      We disign a culture that allows employees to <br></br>
                      adapt quiqly, more confident and challenged <br></br>
                      ini any work situation.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={motivation} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Motivation</h4>
                    <p className="content-skil">
                      We understand motivation significantly help <br></br>
                      everyone to be productive and highly <br></br>
                      perform. So we build a comfortable and <br></br>
                      pleasant working atmosphere to help <br></br>
                      employee achievetheir goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="office">
            <div className="office-title">
              <div className="head">Office Activities</div>
              <div className="content">
                Each division has its own responsibility in each field and
                coordinates with each other to achieve a desired goal...
              </div>
            </div>
            <div className="images">
              <img src={sliderData.value} className="images-result" />
            </div>

            <div className="flex-row">
              {imgs.map((data, i) => (
                <div className="thumb">
                  <img
                    key={data.id}
                    src={data.value}
                    onClick={() => handleclick(i)}
                    className="thumb-result"
                  />
                </div>
              ))}
            </div>
            {/* <div className="row justify-content-center row-office">
              <div className="col-lg-3 col-md-8 col-sm-12 col-office-1">
                <img src={office1} alt="Hria" className="office1" />
                <img src={office2} alt="Hria" className="office2" />
              </div>

              <div className="col-lg-6 col-md-9 col-sm-12 col-office-2">
                <img src={office3} alt="Hria" className="office3" />
              </div>

              <div className="col-lg-3 col-md-8 col-sm-12 col-office-3">
                <img src={office4} alt="Hria" className="office4" />
                <img src={office5} alt="Hria" className="office4" />
              </div>
            </div> */}
          </div>
          {loader}
        </div>
      ) : (
        <div className="container container-content">
          <div className="row row-content">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 content-title">
              <h2 className="title-1">
                Welcome to <span className="title-2">Anyar Career</span>
              </h2>
              <p className="title-3">
                Join to be a part of the ATEAM family!<br></br>
                Anyar Group can be a place to grow, develop and find your true
                passion..
              </p>
              <Link to="/register">
                <button className="button-join">
                  <a className="join">Ready To Join Us ?</a>
                </button>
              </Link>
              <div className="drop-resume">
                <h6 className="title-1">
                  Position you want is not available?{" "}
                  <Link to="/register" className="title-2">
                    Drop resume
                  </Link>
                </h6>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 content-image">
              <img src={human} alt="Hria" className="img-dash" />
            </div>
          </div>

          <div className="ava-vacancy">
            <div className="ava-vacancy-title">
              <h2 className="ava-vacancy-title-1">Available Vacancy</h2>
            </div>
            <div className="row justify-content-center row-vacancy">
              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={rkm} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>

              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={tri} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>

              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={ld} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>

              <div className="col-vacancy col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <Link to="/vacancy">
                  <div className="card">
                    <img src={ho} alt="Hria" className="vacancy-img" />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="get">
            <div className="get-title">
              <h2 className="get-title-1">Let’s Talk About Perks...</h2>
            </div>
          </div>

          {showTopBtn && (
            <div className="skil">
              <div className="row justify-content-center row-skil">
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={achiev} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Achievement</h4>
                    <p className="content-skil">
                      We are principled to be able do develop <br></br>
                      employee career by recognize your passions<br></br>
                      and interests to improve employee<br></br>
                      themselve, their role, and the business in <br></br>
                      general.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={teamwork} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Team Work</h4>
                    <p className="content-skil">
                      Skill can win battles, but teamwork can won <br></br>
                      the war, In Anyar Group, we work as a team <br></br>
                      cause two always been better than one.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={engagement} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Engagement</h4>
                    <p className="content-skil">
                      Anyar Group believe that employees are out<br></br>
                      biggest asset, the essential factors for<br></br>
                      organization’s success. We value and put the<br></br>
                      well-being of your satisfaction as a priority.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={adap} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Adaptif</h4>
                    <p className="content-skil">
                      We disign a culture that allows employees to <br></br>
                      adapt quiqly, more confident and challenged <br></br>
                      ini any work situation.
                    </p>
                  </div>
                </div>
                <div className="col-skil col-lg-4 col-md-6 col-sm-6">
                  <div className="card card-skil">
                    <img src={motivation} alt="Hria" className="image-skil" />

                    <h4 className="title-skil">Motivation</h4>
                    <p className="content-skil">
                      We understand motivation significantly help <br></br>
                      everyone to be productive and highly <br></br>
                      perform. So we build a comfortable and <br></br>
                      pleasant working atmosphere to help <br></br>
                      employee achievetheir goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="office">
            <div className="office-title">
              <div className="head">Office Activities</div>
              <div className="content">
                Each division has its own responsibility in each field and
                coordinates with each other to achieve a desired goal...
              </div>
            </div>
            <div className="images">
              <img src={sliderData.value} className="images-result" />
            </div>

            <div className="flex-row">
              {imgs.map((data, i) => (
                <div className="thumb">
                  <img
                    key={data.id}
                    src={data.value}
                    onClick={() => handleclick(i)}
                    className="thumb-result"
                  />
                </div>
              ))}
            </div>
            {/* <div className="row justify-content-center row-office">
              <div className="col-lg-3 col-md-8 col-sm-12 col-office-1">
                <img src={office1} alt="Hria" className="office1" />
                <img src={office2} alt="Hria" className="office2" />
              </div>

              <div className="col-lg-6 col-md-9 col-sm-12 col-office-2">
                <img src={office3} alt="Hria" className="office3" />
              </div>

              <div className="col-lg-3 col-md-8 col-sm-12 col-office-3">
                <img src={office4} alt="Hria" className="office4" />
                <img src={office5} alt="Hria" className="office4" />
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
