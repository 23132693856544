import '../css/style.scss';
import '../css/resetpass/resetpass.scss';
import axios from 'axios';
import reset from '../img/reset.png';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router';
import { Link } from "react-router-dom";
import Swal from "sweetalert2"; 

function Resetpass() {

    const history = useHistory();
    const { register, formState: { errors }, handleSubmit, getValues  } = useForm();
    const [isLoading, setLoading] =  useState(false);
   
    useEffect(() => {
    if(localStorage.getItem('token')) {
       
    }
    }, []);

    const onSubmit = async (e) => {
    setLoading(true);
        const values = getValues();
        await axios.post('https://hris.anyargroup.co.id/api/password/email', values)
        .then((response) => {
            history.push('/verifemail');
            setLoading(false)
        })
        .catch((error) => {
            console.log("ini dalah = ",error.response.data.message)
            if(error.response.data.message === "Email Tidak Terdaftar"){
                Swal.fire({
                  title: "Please check your email!!",
                  showConfirmButton: false,
                  icon: "warning",
                })
                
            }
            setLoading(false)
        })
    };

return (

    <div className="container container-resetpass">
        <div className="row justify-content-center row-reset">
            <div className="col-md-6 col-xl-5 col-lg-5 col-sm-12 col-xs-12 col-reset">
                <div className="title-reset">
                    <h2 className="title-content">Reset Your <span className="title-content-1">Password</span>
                     </h2>
                </div>
                <div className="title-reset2">
                    <h2 className="title-content2">
                    Input your Email, system will send you verification code<br></br>
                    to your email. Make sure your email is registered
                     </h2>
                </div>
                    <div className="card card-reset">
                        <div className="card-body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="email">
                                    <label className="form-label">Email</label>
                                        <input  type="email"
                                                name="email1" 
                                                className="form-email form-control" 
                                                placeholder="Input Email"
                                                {...register("email", {required:true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i })}/>
                                        <span style={{ color: "red" , textAlign: "center", fontSize: "14px" ,  fontWeight: "700"}}> {errors.email?.type === 'required' && "Email can't be empty"}</span>
                                        <span style={{ color: "red" , textAlign: "center", fontSize: "14px",    fontWeight: "700"}}> {errors.email?.type === 'pattern' && "Email can't be Empty"}</span>
                                </div>
                                <div className="row row-btn">
                                    <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-btn">
                                        <div className="button-reset">
                                            {!isLoading &&  <button type="submit" className="btn btn-reset" ><span className="title-reset">Send</span></button> } 
                                            {isLoading &&  <button type="submit" className="btn btn-reset" disabled><i className="fas fa-spinner fa-spin"></i> <span className="title-reset">Sign In</span></button> } 
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="back-login">
                                <Link to={"/login"} className="link-back-login">
                                    <h5 className="title-back-login">Back to login</h5>
                                </Link>
                            </div>
                        </div>
                    </div>  
            </div>

            <div className="col-md-6 col-xl-7 col-lg-7 col-sm-12 col-xs-12 col-img-reset">
                <div className="img-reset">
                    <img class="img-reset-content" src={reset} alt="Logo"/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Resetpass;
